/*COLORS*/
/*TIPOGRAFIAS*/
/**/
/* 960px */
/*COLORS*/
/*TIPOGRAFIAS*/
/**/
/* 960px */
/*============== SECTION NOTICIAS HOME ==============*/

.btn-continuar {
  text-align: center;
  clear: both;
  /*margin-top: 20px;*/

  padding-top: 30px;
}
.btn-continuar button {
  background: #fa9600;
  padding: 10px 70px;
  color: #fff;
  border: none;
}
.ui-helper-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.sect-noticias {
  float: right;
  width: 370px;
  height: 165px;
  position: relative;
}
.sect-noticias .deco-sect-noticias {
  /*background: url(../../images/Pescadores/deco-sect-noticias.png) 0 0 repeat;*/
  bottom: 15px;
  height: 43px;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 1;
}
.sect-noticias h3 {
  background: #777777;
  padding: 0 10px;
  color: #fff;
  display: inline-block;
  position: relative;
  margin-bottom: 14px;
  font-size: 13px;
}
.sect-noticias h3 span {
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 7px;
  height: 4px;
  /*background: url(../../images/Pescadores/flecha.png) 0 0 no-repeat;*/
}
#carou-vertical.carousel-vertical .items {
  float: left;
  clear: both;
  width: 100%;
  position: relative;
  margin-bottom: 10px;
  font-size: 12px;
  -webkit-transition: 0.6s ease-in-out top !important;
  -moz-transition: 0.6s ease-in-out top !important;
  -ms-transition: 0.6s ease-in-out top !important;
  -o-transition: 0.6s ease-in-out top !important;
  transition: 0.6s ease-in-out top !important;
}
#carou-vertical.carousel-vertical .carousel-inner {
  overflow: hidden;
  height: 95px;
}
#carou-vertical.carousel-vertical .carousel-inner div.active + div {
  display: block;
}
.carousel-vertical .date,
.carousel-vertical .cont-items {
  float: left;
}
.carousel-vertical .date {
  width: 15%;
}
.carousel-vertical .cont-items {
  width: 80%;
}
.carousel-vertical strong {
  font-family: open_sanssemibold;
  font-weight: normal;
}
.carousel-control-vert {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1001;
}
.carousel-vertical-vert a {
  text-decoration: none;
}
.carousel-control-vert a {
  width: 37px;
  height: 24px;
  float: left;
  /*background: url(../../images/Pescadores/carousel-control-vert-up-down.png) 0 0 no-repeat;*/
  text-indent: -9999px;
}
.carousel-vertical .control-up {
  background-position: 11px center;
  border-top: 1px solid #aaaaaa;
  border-left: 1px solid #aaaaaa;
  border-right: 1px solid #aaaaaa;
}
.carousel-vertical .control-down {
  background-position: -26px center;
  border-top: 1px solid #aaaaaa;
  border-right: 1px solid #aaaaaa;
}
/* CUSTOMIZE THE CAROUSEL
-------------------------------------------------- */
/* Carousel base class */
/*.carousel {
  height: 500px;
  margin-bottom: 60px;
}*/
/* Since positioning the image, we need to help out the caption */
.carousel-caption {
  z-index: 10;
}
/* Declare heights because of positioning of img element */
#banner .owl-carousel .item {
  height: 371px;
}
#banner .esp .slides .item {
  height: 200px;
}
#banner .owl-carousel.esp .item .carousel-caption,
#banner .esp .slides .item .carousel-caption {
  bottom: auto;
  left: 0;
  right: 0;
  width: 100%;
}

#banner .slides .item > img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}
#banner .owl-carousel .item .carousel-caption {
  bottom: 25%;
  /*left: 2%;
  right: auto;
  top: auto;*/
  width: 30%;
}
#banner .flexslider.site .item .carousel-caption,
#banner .site .slides .item .carousel-caption {
  bottom: 25%;
  left: 2%;
  text-align: left;
  width: 30%;
}
#banner .flexslider.site.center .item .carousel-caption {
  text-align: center;
}
#banner .flexslider .item .carousel-caption .bajada p {
  font-size: 1.385em;
}
#banner .flexslider .item .carousel-caption {
  /*text-align: left;*/
  bottom: 35%;
  /*width: 40%;*/
}
#banner .flexslider.inversiones .item .carousel-caption h2 {
  margin-bottom: 0.5em;
}
#banner .flexslider .item,
#banner .slides .item {
  height: 380px;
  position: relative;
  overflow: hidden;
}
#banner .flexslider .item.landing {
  height: 200px;
}
#banner .flexslider .item.landing .carousel-caption {
  bottom: 20%;
  left: 2%;
  right: auto;
  top: auto;
  width: auto;
}
#banner .flexslider .item.landing .carousel-caption.serv-cl .search {
  float: none;
  margin-top: 1em;
  margin-left: auto;
  margin-right: auto;
}
#banner
  .flexslider
  .item.landing
  .carousel-caption.serv-cl
  .search
  input[type='text'] {
  height: 32px;
}
#banner .flexslider .item.landing .carousel-caption.serv-cl .search .btn {
  top: 12px;
}
#banner .flexslider .item.landing .carousel-caption h2 {
  font-size: 3.7em;
  font-family: open_sanslight;
}
#banner .flexslider .item.landing.center .carousel-caption {
  top: 20%;
  left: 0;
  right: 0;
}
#banner .flexslider .item.landing.center .carousel-caption h2 {
  font-size: 2.7em;
}
#banner .flexslider .item.landing.new-head {
  height: 100px;
}
#banner .flexslider .item.landing.new-head .container {
  margin-top: 1em;
  height: 100px;
}
#banner .flexslider .item.landing.new-head .container .carousel-caption h2 {
  color: #5d3261;
  font-size: 2.6em;
}
#banner
  .flexslider.blackTitle
  .item.landing.new-head
  .container
  .carousel-caption {
  left: 0;
}
#banner
  .flexslider.blackTitle
  .item.landing.new-head
  .container
  .carousel-caption
  h2 {
  color: #333;
  font-family: 'open_sanssemibold';
}
#banner .flexslider .item.landing.new-head .container .carousel-caption h2 img {
  float: left;
  margin: -5px 10px 0 0;
}
#banner .flexslider.hcliente .item {
  height: 265px;
}
#banner .flexslider.hcliente .item .carousel-caption.center {
  padding: 7% 0;
}
#banner ol.flex-control-nav {
  bottom: 10px;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: 100;
}

#banner ol.flex-control-nav li a {
  background-color: #aaa;
  cursor: pointer;
  height: 15px;
  text-indent: -999px;
  transition: all 0.4s ease 0s;
  width: 15px;
  display: block;
  text-indent: -9999px;
}
#banner ol.flex-control-nav li a.flex-active {
  background-color: #fa9600;
}
#banner ol.flex-control-nav li {
  display: inline-block;
  zoom: 1;
  margin: 0 5px;
}
.header img {
  width: 100%;
  /* max-width: 1200px; */
}
.owl-theme .owl-controls .owl-page.active span,
.owl-theme .owl-controls.clickable .owl-page:hover span {
  background-color: #fa9600;
}
.carousel-inner .item > img {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  height: 500px;
}
.carousel {
  position: relative;
  min-width: 100%;
}
.carousel-inner {
  position: relative;
  min-width: 100%;
  overflow: hidden;
}
#myCarousel.esp .carousel-inner,
#myCarousel.esp .carousel-inner.item {
  width: 100% !important;
}
.carousel-inner > .item {
  position: relative;
  display: none;
  -webkit-transition: 0.6s ease-in-out left;
  transition: 0.6s ease-in-out left;
}
.carousel-inner .item > img,
.carousel-inner .item > a > img {
  display: block;
  height: auto;
  /*max-width: 100%;*/

  line-height: 1;
  min-width: 1160px;
  width: 100%;
}
.carousel-inner > .active,
.carousel-inner > .next,
.carousel-inner > .prev {
  display: block;
}
.carousel-inner > .active {
  left: 0;
}
.carousel-inner > .next,
.carousel-inner > .prev {
  position: absolute;
  top: 0;
  width: 100%;
}
.carousel-inner > .next {
  left: 100%;
}
.carousel-inner > .prev {
  left: -100%;
}
.carousel-inner > .next.left,
.carousel-inner > .prev.right {
  left: 0;
}
.carousel-inner > .active.left {
  left: -100%;
}
.carousel-inner > .active.right {
  left: 100%;
}
.carousel-control {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 15%;
  font-size: 20px;
  color: #ffffff;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
  opacity: 0.5;
  filter: alpha(opacity=50);
  /*background: black;*/
}
/*.carousel-control.left {
  background-image: -webkit-linear-gradient(left, color-stop(rgba(0, 0, 0, 0.5) 0), color-stop(rgba(0, 0, 0, 0.0001) 100%));
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5) 0, rgba(0, 0, 0, 0.0001) 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#80000000', endColorstr='#00000000', GradientType=1);
}*/
.carousel-control.right {
  right: 0;
  left: auto;
  /* background-image: -webkit-linear-gradient(left, color-stop(rgba(0, 0, 0, 0.0001) 0), color-stop(rgba(0, 0, 0, 0.5) 100%));
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.0001) 0, rgba(0, 0, 0, 0.5) 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#80000000', GradientType=1);*/
}
.carousel-control:hover,
.carousel-control:focus {
  color: #ffffff;
  text-decoration: none;
  outline: none;
  opacity: 0.9;
  filter: alpha(opacity=90);
}
.carousel-control .icon-prev,
.carousel-control .icon-next,
.carousel-control .glyphicon-chevron-left,
.carousel-control .glyphicon-chevron-right {
  position: absolute;
  top: 50%;
  z-index: 5;
  display: inline-block;
}
.carousel-control .icon-prev,
.carousel-control .glyphicon-chevron-left {
  left: 50%;
}
.carousel-control .icon-next,
.carousel-control .glyphicon-chevron-right {
  right: 50%;
}
.carousel-control .icon-prev,
.carousel-control .icon-next {
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  font-family: serif;
}
.carousel-control .icon-prev:before {
  content: '\2039';
}
.carousel-control .icon-next:before {
  content: '\203a';
}
.carousel-indicators {
  position: absolute;
  bottom: 10px;
  left: 50%;
  z-index: 15;
  width: 60%;
  padding-left: 0;
  margin-left: -30%;
  text-align: center;
  list-style: none;
}
#banner .carousel-caption {
  width: 40%;
  position: absolute;
  right: 10%;
  top: 25%;
  color: #fff;
}
#banner .flexslider .carousel-caption.center {
  margin: 0 auto;
  padding: 15% 0;
  position: static;
  width: 70%;
}

#banner .carousel-caption.center h2 {
  font-size: 2.615em;
  line-height: 1.2em;
}
#banner .carousel-caption.center.esp h2,
#banner .carousel-caption.center.esp h3 {
  font-size: 3.077em;
  line-height: 1.2em;
}
#banner .carousel-caption.center.esp .bajada {
  margin-top: 0.5em;
}
#banner .carousel-caption img {
  margin-bottom: 10px;
}
#banner .carousel-caption h2 {
  font-size: 28px;
  font-family: open_sanssemibold;
  line-height: 40px;
}
#banner .carousel .item.landing .carousel-caption h2 {
  font-family: open_sanslight;
}
#banner .carousel-caption h3 {
  font-family: open_sanslight;
  font-size: 28px;
  line-height: 30px;
}
#banner .carousel-caption .accion.accion-img {
  margin-top: 20px;
}
/*.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #ffffff;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
}*/
#banner .item .container {
  height: 100%;
  margin: 0 auto;
  position: relative;
  max-width: 940px;
  z-index: 10;
}
.carousel-indicators li {
  display: inline-block;
  width: 15px;
  height: 15px;
  margin: 1px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #aaaaaa;
  transition: all 0.4s;
}
.carousel-indicators .active {
  background-color: #fa9600;
}
.carousel-caption .btn {
  text-shadow: none;
}
/*============== MENÚ MIGA ==============*/
#miga {
  border-top: 1px #ddd solid;
  border-bottom: 1px #ddd solid;
  overflow: hidden;
  height: 33px;
  position: relative;
}
#miga ul {
  background-color: #f9f9f9;
  overflow: hidden;
  position: relative;
  z-index: 1002;
}
#miga ul li {
  float: left;
  position: relative;
  z-index: 1;
}
#miga ul li a {
  color: #777777;
}
#miga ul li a i.icon-home {
  /*background: url(../../images/Pescadores/icon-home-miga.png) center center no-repeat;*/
}
#miga ul li a,
#miga ul li span {
  /*background: url(../../images/Pescadores/deco-miga-landing.png) right top no-repeat #ffffff;*/
  display: block;
  padding: 9px 20px 10px 10px;
  font-size: 12px;
}
#miga ul li span {
  color: #333333;
}
#miga ul li:last-child {
  margin-left: -9px;
  z-index: 2;
}
#miga ul li:last-child span {
  /*background: url(../../images/Pescadores/deco-miga-landing-actual.jpg) -14px top no-repeat transparent;*/
  padding-left: 25px;
  font-family: open_sanssemibold;
}

.bg-miga {
  position: absolute;
  top: 0;
  right: 0;
  background: #f9f9f9;
  display: block;
  width: 40%;
  height: 33px;
  z-index: 1001;
}

/*============== TABS ==============*/
.list-selector-mobile .nav-tabs.collapse {
  display: block;
  height: auto !important;
}
.nav-tabs.vertical {
  width: 25%;
  float: left;
}
.nav-tabs > li {
  float: left;
  margin-bottom: -3px;
}
.nav > li {
  display: block;
  position: relative;
}
.nav-tabs.vertical > li {
  width: 100%;
}
.nav-tabs.vertical > li > a {
  margin-right: 0;
}
.nav-tabs.vertical > li > a:hover {
  background-color: #fa9600;
  color: #fff;
}
.nav-tabs.vertical.white > li > a:hover {
  background-color: #fff;
  color: #1f1f1f;
}
.nav-tabs.vertical > li > a:hover {
  background-color: #fa9600;
}
.nav-tabs > li > a {
  border: 1px solid rgba(0, 0, 0, 0);
  line-height: 1.42857;
  margin-right: 2px;
}
.nav > li > a {
  display: block;
  padding: 10px 15px;
  position: relative;
}
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  background-color: #ffffff;
  color: #1f1f1f;
  cursor: default;
}
.tab-content {
  border-top: 1px solid #e5e5e5;
  margin-top: -1px;
  background-color: #fff;
  width: 94%;
  display: inline-block;
  padding: 3%;
}
.tab-content h3 {
  color: #000;
  margin-bottom: 20px;
}
.tab-content p {
  margin-bottom: 20px;
  line-height: 20px;
}
.tab-content ul li {
  /*background: url(../../images/Pescadores/bullet-temas-interes.jpg) left 5px no-repeat;*/
  padding-left: 10px;
  margin-bottom: 10px;
  line-height: 130%;
}
.tab-content ul li > ul li {
  margin: 10px 0;
}
.tab-content img {
  float: left;
  margin: 0 10px 10px 0;
}
.tab-content > .tab-pane {
  display: none;
}
.tab-content.vertical {
  border-left: 1px solid #e5e5e5;
  border-top: none;
  float: left;
  margin-top: 0;
  margin-left: -1px;
  width: 68%;
  min-height: 230px;
}
.fade.in {
  opacity: 1;
}
.fade {
  opacity: 0;
  transition: opacity 0.15s linear 0s;
}
.tab-content > .active {
  display: block;
}
/*============== TOOLTIP ==============*/
.tooltip {
  display: block;
  font-size: 12px;
  line-height: 1.4;
  opacity: 0;
  position: absolute;
  visibility: visible;
  z-index: 1030;
}
.tooltip.in {
  opacity: 1;
}
.tooltip.top {
  margin-top: -3px;
  padding: 5px 0;
}
.tooltip.right {
  margin-left: 3px;
  padding: 0 5px;
}
.tooltip.bottom {
  margin-top: 3px;
  padding: 5px 0;
}
.tooltip.left {
  margin-left: -3px;
  padding: 0 5px;
}
.tooltip-inner {
  background-color: #f6f6f6;
  border-radius: 4px;
  color: #333;
  max-width: 200px;
  padding: 3px 8px;
  text-align: center;
  text-decoration: none;
  box-shadow: 0 0 5px #ccc;
  moz-box-shadow: 0 0 5px #ccc;
  -webkit-box-shadow: 0 0 5px #ccc;
}
.tooltip-arrow {
  border-color: #fff;
  border-style: solid;
  height: 0;
  position: absolute;
  width: 0;
}
.tooltip.top .tooltip-arrow {
  border-top-color: #f6f6f6;
  border-width: 5px 5px 0;
  bottom: 0;
  left: 50%;
  margin-left: -5px;
}
.tooltip.top-left .tooltip-arrow {
  border-top-color: #ddd;
  border-width: 5px 5px 0;
  bottom: 0;
  left: 5px;
}
.tooltip.top-right .tooltip-arrow {
  border-top-color: #ddd;
  border-width: 5px 5px 0;
  bottom: 0;
  right: 5px;
}
.tooltip.right .tooltip-arrow {
  border-right-color: #f6f6f6;
  border-width: 5px 5px 5px 0;
  left: 0;
  margin-top: -5px;
  top: 50%;
}
.tooltip.left .tooltip-arrow {
  border-left-color: #f6f6f6;
  border-width: 5px 0 5px 5px;
  margin-top: -5px;
  right: 0;
  top: 50%;
}
.tooltip.bottom .tooltip-arrow {
  border-bottom-color: #f6f6f6;
  border-width: 0 5px 5px;
  left: 50%;
  margin-left: -5px;
  top: 0;
}
.tooltip.bottom-left .tooltip-arrow {
  border-bottom-color: #f6f6f6;
  border-width: 0 5px 5px;
  left: 5px;
  top: 0;
}
.tooltip.bottom-right .tooltip-arrow {
  border-bottom-color: #f6f6f6;
  border-width: 0 5px 5px;
  right: 5px;
  top: 0;
}
.help-block {
  color: #737373;
  display: block;
  font-size: 11px;
}
/*============== DESPLEGABLES ==============*/
.collapse {
  display: none;
}
.collapse.in {
  display: block;
}
.collapsing {
  height: 0;
  overflow: hidden;
  position: relative;
  transition: height 0.35s;
  -moz-transition: height 0.35s;
  -webkit-transition: height 0.35s;
}
.panel-group {
  margin-bottom: 20px;
}
.panel-group .panel {
  margin-bottom: 0;
  overflow: hidden;
}
.panel-group .panel:last-child .panel-heading {
  border-bottom: 1px solid #ccc;
}
.panel-default {
  border-color: #dddddd;
}
.panel {
  background-color: #ffffff;
  margin-bottom: 20px;
}
.panel-default > .panel-heading {
  border-color: #dddddd;
  color: #202020;
}
.panel-heading {
  /*background: url("../../images/Pescadores/flecha-desplegable.jpg") no-repeat right -20px;*/
  border: 1px solid #ccc;
  border-right: none;
  border-bottom: none;
  border-left: none;
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
}
.panel-heading.show {
  background-position: right -100px;
}
.panel-title {
  color: inherit;
  font-size: 14px;
  margin-bottom: 0;
  margin-top: 0;
  line-height: normal;
}
.panel-title > a {
  color: inherit;
  display: block;
  padding: 10px 45px 10px 15px;
}
.panel-body {
  background-color: #f1f3f2;
  padding: 20px;
  line-height: 22px;
}
.panel-collapse {
  margin-top: -1px;
}
/*============== DESPLEGABLES ==============*/
.form-general input[type='checkbox'] {
  margin-top: -2px;
}

.form-general .has-feedback {
  float: left;
  width: 95%;
  margin-right: 1%;
  /*height: 60px;*/
}
.has-feedback {
  position: relative;
}
.has-feedback .form-control-feedback {
  display: block;
  height: 28px;
  line-height: 28px;
  position: absolute;
  right: 7%;
  text-align: center;
  top: 7px;
  width: 28px;
}
.has-success .form-control {
  border-color: #00419a;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
}
.has-error .form-control {
  border-color: #a94442;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
}
.touch-carousel {
  position: relative;
  overflow: hidden;
  width: 100%;
  -webkit-transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
  -webkit-backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.touch-carousel .carousel-control {
  display: none !important;
}
.touch-carousel .carousel-indicators {
  bottom: 10px;
}
.touch-carousel .carousel-indicators li {
  width: 16px;
  height: 16px;
  margin: 1px 5px;
}
.touch-carousel .carousel-indicators li.active {
  margin: 0px 5px;
  width: 18px;
  height: 18px;
}
.touch-carousel .carousel-inner {
  overflow: hidden;
  -webkit-transform: translate3d(0%, 0, 0) scale3d(1, 1, 1);
  -moz-transform: translate3d(0%, 0, 0) scale3d(1, 1, 1);
  -ms-transform: translate3d(0%, 0, 0) scale3d(1, 1, 1);
  transform: translate3d(0%, 0, 0) scale3d(1, 1, 1);
  -webkit-backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.touch-carousel .carousel-inner > .item {
  position: relative;
  float: left;
  display: block;
  max-width: 100%;
  -webkit-transition: none;
  -moz-transition: none;
  transition: none;
  -webkit-backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
}
.touch-carousel .carousel-inner > .item * {
  -webkit-transition: none;
  -moz-transition: none;
  transition: none;
}
.touch-carousel .carousel-inner.animate {
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
@media screen and (max-width: 767px) {
  .carousel-caption {
    display: none;
  }
  #banner-noticias .carousel-caption {
    display: block;
  }
}
/*============== PAGINADOR ==============*/
.paginador {
  float: left;
  width: 100%;
  background: #fff;
  overflow: hidden;
  position: relative;
}
.paginador ul {
  clear: left;
  float: left;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  left: 50%;
  text-align: center;
}
.paginador ul li {
  display: block;
  float: left;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  right: 50%;
  margin-right: 10px;
}
.paginador ul li a {
  background: #aaaaaa;
  color: #fff;
  display: block;
  font-family: open_sansbold;
  height: 22px;
  line-height: 22px;
  padding: 0 8px;
  text-decoration: none;
  border-radius: 20px;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
}
.paginador ul li a:hover {
  background: #e17628;
  color: #fff !important;
}
.paginador ul li a.active,
.paginador ul li a.active:hover {
  background: #fff;
  border: 2px solid #e17628;
  color: #e17628;
  font-weight: bold;
  height: 19px;
  line-height: 18px;
  padding: 0 6px;
}
.paginador ul li a.prev-pag,
.paginador ul li a.next-pag {
  /*background: url("../../images/Pescadores/btn-paginador.png") no-repeat center top;*/
  height: 23px;
  width: 5px;
  text-indent: -9999px;
}
.paginador ul li a.prev-pag {
  background-position: center 7px;
}
.paginador ul li a.next-pag {
  background-position: center -22px;
}
.paginador ul li a.next-group {
  background: #fff;
  color: #aaaaaa;
  line-height: 27px;
  padding: 0;
}
.paginador ul li a.next-group:hover {
  color: #e17628;
}
/* CHECKBOX PERSONALIZADOS */
.checkbox input[type='checkbox'],
.radio input[type='radio'] {
  visibility: hidden;
}

.checkbox,
.radio {
  position: relative;
}
.checkbox label,
.radio label {
  /*background: url("../../images/Pescadores/bg-checkbox.png") no-repeat left top;*/
  display: block;
  min-height: 15px;
  cursor: pointer;
  padding-left: 20px;
  z-index: 1;
}
.checkbox label,
.radio label {
  float/*\**/: left\9;
  display/*\**/: inherit\9;
  background/*\**/: none\9;
}
.checkbox input[type='checkbox'],
.radio input[type='radio'] {
  visibility/*\**/: visible\9;
  float/*\**/: left\9;
}
.checkbox,
.radio {
  height/*\**/: 40px\9;
}
.checkbox label {
}
.checkbox input[type='checkbox']:checked + label,
.radio input[type='radio']:checked + label {
  background-position: left -80px;
}
/* carrusel vertical */
.jcarousel {
  height: 100px;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.wrapper-carousel-vertical {
  width: 100%;
  height: 100px;
  position: relative;
}
.jcarousel ul {
  list-style: none outside none;
  margin: 0;
  padding: 0;
  position: relative;
  /*width: 20000em;*/
}
.jcarousel li {
  margin-bottom: 0.5em;
  overflow: hidden;
}
.jcarousel li .date {
  float: left;
  width: 15%;
}
.jcarousel li .cont-items {
  float: left;
  width: 75%;
}
.jcarousel-control-prev,
.jcarousel-control-next {
  /*background: url("../../images/Pescadores/carousel-control-vert-up-down.png") no-repeat left center;*/
  border: 1px solid #ccc;
  bottom: -24px;
  color: #fff;
  height: 20px;
  padding: 0 15px;
  position: absolute;
  text-indent: -9999px;
}
.jcarousel-control-prev {
  background-position: -29px center;
  right: 31px;
}
.jcarousel-control-next {
  background-position: 8px center;
  right: 0;
}
.jcarousel-control-prev:hover span,
.jcarousel-control-next:hover span {
  display: block;
}
.jcarousel-control-prev.inactive,
.jcarousel-control-next.inactive {
  cursor: default;
  opacity: 0.5;
}
.jcarousel-pagination {
  bottom: 0;
  left: 15px;
  position: absolute;
}
.jcarousel-pagination a {
  background: none repeat scroll 0 0 #ffffff;
  border-radius: 14px;
  color: #4e443c;
  display: inline-block;
  font-size: 11px;
  line-height: 14px;
  margin-right: 2px;
  min-width: 14px;
  opacity: 0.75;
  padding: 3px;
  text-align: center;
  text-decoration: none;
}
.jcarousel-pagination a.active {
  background: none repeat scroll 0 0 #4e443c;
  color: #ffffff;
  opacity: 1;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.75);
}
/*COLORS*/
/*TIPOGRAFIAS*/
/**/
/* 960px */
/*============== HEADER ==============*/
body.noscroll {
  overflow: hidden;
  position: fixed;
  height: 100%;
}
#header {
  position: relative;
  z-index: 1003;
  clear: both;
}
#header a {
  text-decoration: none;
}
/*== NAV-TOP ==*/
#header .nav-top {
  background: #777777;
  font-size: 11px;
  line-height: 30px;
}
#header .nav-top ul {
  display: table;
  width: 100%;
}
#header .nav-top li {
  display: table;
  min-height: 27px;
  width: 110px;
  vertical-align: middle;
}
#header .nav-top li {
  float: left;
  min-height: 27px;
  height: 27px;
  width: 110px;
}
#header .nav-top li.longer {
  float: left;
  min-height: 27px;
  width: 130px;
}
#header .nav-top a {
  color: #fff;
  text-align: center;
  padding: 0;
  display: table-cell;
  margin: 0 auto;
  line-height: normal;
  vertical-align: middle;
}
#header .nav-top li:hover {
  background-color: #fff;
}
#header .nav-top li:hover a {
  color: #222;
}
.btn-login-smartphone {
  display: none;
}
.inner-content#logo {
  float: left;
  margin-top: 28px;
  width: 186px;
}
#nav-top-select {
  display: none;
}
/*== NAV ==*/
#nav {
  width: 710px;
  height: 35px;
  background: #b91a28;
  float: right;
  position: relative;
  z-index: 1000;
}
#nav .principal {
  float: left;
  width: 75%;
}
#nav > .principal > li {
  float: left;
  height: 35px;
  line-height: 35px;
  position: relative;
}
/*#nav > .principal > li + li {
    margin-left: 10px;
}*/
#nav > .principal > li > a {
  /*background: url(../../images/Pescadores/bullet-menu-principal.png) 98% 27px no-repeat;*/
  display: block;
  color: #fff;
  padding: 0 15px;
  font-size: 1.077em;
}
#nav > .principal > li:hover {
  background: #fff;
}
#nav > .principal > li:hover a .icon-home {
  /*background: url("../../images/Pescadores/icon-home-miga.png") no-repeat center center;*/
}
#nav > .principal > li:hover > a {
  color: #333;
}
.current {
  background: #861a28;
}
#nav .principal li .home {
  padding: 0;
  width: 30px;
  text-align: center;
}
#nav .principal li .home span {
  display: none;
}
/*#nav .principal li .home .icon-home {
  margin-top: 10px;
}*/
/*== SUBMENU ==*/
#nav li:hover .sub-menu {
  display: block;
  box-shadow: 2px 2px 5px #777;
  -webkit-box-shadow: 2px 2px 5px #777;
  -moz-box-shadow: 2px 2px 5px #777;
  border: 1px solid #eee;
}

.sub-menu {
  display: none;
  position: absolute;
  top: 35px;
  z-index: 1002;
  background: #fff;
  width: 545px;
  padding: 20px;
}
.sub-menu .menu h3 {
  margin-bottom: 20px;
}
.sub-menu .section-menu {
  width: 140px;
  float: left;
  padding-right: 30px;
  border-right: 1px #ddd solid;
  margin-right: 30px;
}
.sub-menu .section-menu a:hover {
  color: #b91a28;
}
.sub-menu .section-menu:last-child {
  border-right: none;
  margin-right: 0;
  padding-right: 0;
}
.sub-menu .section-menu ul {
  width: 100%;
  padding-bottom: 30px;
}
.sub-menu .section-menu ul li {
  line-height: normal;
  margin-bottom: 5px;
}
.sub-menu .section-menu ul li a {
  display: block;
  font-size: 14px;
}
.sub-menu .section-menu ul li a.titleCategory {
  color: #b91a28;
  font-family: open_sanssemibold;
}
#nav li:nth-child(2) .sub-menu,
#nav li:nth-child(3) .sub-menu,
#nav li:nth-child(4) .sub-menu {
  left: 0;
}
#nav li:nth-child(5) .sub-menu {
  right: 0;
}
/*== HEAD AYUDA ==*/
.ayuda-head {
  height: 63px;
}
.ayuda-head .ayuda {
  margin: 26px 22px 0 0;
  float: right;
}
.ayuda-head .ayuda li {
  float: left;
}
.smartphone {
  display: none;
}
.ayuda-head .ayuda li span {
  font-size: 14px;
  font-family: open_sanssemibold;
}
.ayuda-head .ayuda li + li {
  border-left: 1px solid #aaaaaa;
  padding-left: 10px;
  margin-left: 10px;
}
.content-nav {
  float: right;
}
/*== SEARCH ==*/
.search {
  background: url('../../images/Pescadores/bg-input.jpg') repeat-x left top
    #ffffff;
  border: 1px solid #aaaaaa;
  margin-top: 18px;
  float: right;
  position: relative;
  overflow: hidden;
  width: 25em;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.search.forModulo {
  margin-top: 0;
  margin-bottom: 2em;
  width: 100%;
}
.search input[type='text'] {
  border: 0;
  float: left;
  font-style: italic;
  padding: 4px 30px 4px 5px;
  width: 80%;
  height: 22px;
  line-height: 22px;
  transition: width 0.5s;
  -moz-transition: width 0.5s;
  -webkit-transition: width 0.5s;
}
.search input[type='text']:hover,
.search input[type='text']:hover {
  width: 150%;
}
.search .btn {
  background: url('../../images/Pescadores/icon-buscador.png') no-repeat center
    top;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 6px;
  right: 5px;
}
.search.xl-search {
  float: none;
  display: block;
  width: 50%;
  margin: 4em auto;
}
/*== BANCA EN LÍNEA ==*/
#banca-linea {
  background: #fff;
  color: #b91a28;
  line-height: 30px;
  float: right;
  text-decoration: none;
  font-size: 1.077em;
  /* 14px */

  width: 23%;
  text-align: center;
}
.bg-head {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 45%;
  height: 35px;
  background: #b91a28;
  z-index: 0;
}
/*============== BANNER HOME ==============*/
#banner {
  width: 100%;
  /*background: @color-9;*/

  clear: both;
  text-align: center;
  overflow: hidden;
  position: relative;
}
/*============== DESTACADOS HOME ==============*/
#destacados-home {
  width: 100%;
  padding: 15px 0 0;
  background: #eee;
  display: block;
  overflow: hidden;
}
#destacados-home .section-cam {
  float: left;
  border-right: 1px solid #aaaaaa;
  padding-right: 20px;
  width: 520px;
}
.art-camp {
  width: 160px;
  height: auto;
  /* 150px */

  border: 1px solid #aaaaaa;
  position: relative;
  float: left;
  cursor: pointer;
  overflow: hidden;
}
.art-camp + .art-camp {
  margin-left: 3%;
  /* 20px */
}
/*.art-camp:hover .cont-art {
    display: block;
}*/
.art-camp .cont-art {
  position: absolute;
  bottom: -160px;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  /*background: url(../../images/Pescadores/bg-cont-art.png) 0 0 repeat;*/
}
.art-camp .cont-art a {
  text-decoration: none;
  text-align: center;
  padding: 30px;
  display: block;
  font-family: open_sanssemibold;
}
/*============== PRODUCTOS ==============*/
#wrap-productos {
  padding-top: 60px;
}
.article-ico {
  text-align: center;
  float: left;
  padding: 0 10px;
  margin-bottom: 20px;
}
.article-ico h3 {
  margin: 10px 0;
}
.article-ico h3 a,
.article-ico h2 a {
  text-decoration: none;
}
.article-ico a {
  text-decoration: underline;
}
.article-ico a:hover {
  color: #b91a28;
}
/*============== FOOTER ==============*/
#footer .grid {
  margin: 0;
  vertical-align: top;
}
#footer .left-foo {
  background-color: #50555c;
  position: relative;
  padding-top: 45px;
  height: 185px;
}
#footer .isotipo-foo {
  float: left;
  margin-right: 2%;
}
#footer .left-foo ul {
  float: left;
  padding-right: 2%;
  width: 45%;
}
#footer .left-foo ul li {
  background: url(../../images/Pescadores/bullet-list-footer.jpg) 0 center
    no-repeat;
  margin-bottom: 10px;
  padding-left: 10px;
}
#footer .left-foo ul li a {
  color: #fff;
  font-size: 13px;
}
#footer .left-foo ul li a:hover {
  color: #aaa;
}
#footer .left-foo ul:last-child,
#footer .left-foo ul:first-child + ul {
  border-right: none;
  margin-right: 0;
  padding-right: 0;
  width: 35%;
}
#footer .right-foo {
  position: relative;
  padding-top: 25px;
}
#footer .right-foo .fono-info {
  font-size: 1.538em;
  /* 20px */

  float: left;
  line-height: 25px;
}
#footer .right-foo .sociales-foo {
  float: right;
}
#footer .right-foo .sociales-foo span {
  line-height: 25px;
  float: left;
  margin-right: 10px;
}
#footer .right-foo .sociales-foo ul {
  float: left;
}
#footer .right-foo .sociales-foo ul li {
  float: left;
  margin-right: 5px;
}
#footer .right-foo .sociales-foo ul li:last-child {
  margin-right: 0;
}
#footer .right-foo .sociales-foo ul li a {
  background-image: url(../../images/Pescadores/icon-rd-footer.png);
  background-repeat: no-repeat;
  display: block;
  width: 28px;
  height: 27px;
  text-indent: -9999px;
}
ul.rd li {
  display: inline-block;
}
ul.rd li a.tw {
  background-image: url(../../images/Pescadores/icon-rd-footer.png);
  background-repeat: no-repeat;
  background-position: 0 center;
  display: block;
  width: 28px;
  height: 27px;
  text-indent: -9999px;
}
ul.rd li a.fb {
  background-image: url(../../images/Pescadores/icon-rd-footer.png);
  background-repeat: no-repeat;
  background-position: -29px center;
  display: block;
  width: 28px;
  height: 27px;
  text-indent: -9999px;
}
#footer .right-foo .sociales-foo ul li a.tw {
  background-position: 0 center;
}
#footer .right-foo .sociales-foo ul li a.fb {
  background-position: -29px center;
}
#footer .right-foo .sociales-foo ul li a.rss {
  background-position: -58px center;
}
#footer .right-foo > ul {
  margin-top: 25px;
  display: inline-block;
  width: 100%;
}
#footer .right-foo > ul li {
  float: left;
  border-right: 1px #ccc solid;
  padding-left: 6px;
  padding-right: 6px;
}
#footer .right-foo > ul li:last-child {
  border-right: none;
  padding-right: 0;
}
#footer .right-foo > ul li:first-child {
  padding-left: 0;
}
#footer .right-foo > ul li a {
  display: block;
  font-size: 0.85em;
}
#footer .right-foo > ul li a:hover {
  color: #000;
  text-decoration: underline;
}
#footer .right-foo .legal {
  margin-top: 30px;
  font-size: 11px;
}

#footer .right-foo .legal .logo-defensoria {
  float: left;
  margin-right: 20px;
  margin-bottom: 15px;
}
#footer .right-foo .legal > p {
  color: #777777;
  font-size: 11px;
  line-height: normal;
}
#footer .right-foo .legal > p a {
  color: #777777;
  text-decoration: underline;
}
.bg-foo {
  background-color: #50555c;
  width: 35%;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
}
/*============== LANDING ==============*/
.content-variable-landing {
  position: relative;
  margin-bottom: 70px;
  border-bottom: 1px solid #ddd;
}
.content-variable-landing.landing2 {
  margin-top: 0;
}
.content-variable-landing .bg-bar {
  /*background: url(../../images/Pescadores/bg-var-content.jpg) left top repeat #eeeeee;*/
  overflow: hidden;
  background-size: 100% 100%;
}
.content-variable-landing .claim {
  float: left;
  padding: 2.3em 0 0 1%;
  width: 20%;
  /* 230px */
}
.content-variable-landing .claim h5 {
  color: #fff;
  font-family: open_sansregular;
  font-size: 20px;
  line-height: 23px;
}
.content-variable-landing .claim h6 {
  color: #fff;
  font-size: 20px;
  line-height: 23px;
}
.content-variable-landing .var-content {
  background-color: #eee;
  /*border: 1px #ddd solid;*/
  border-left: none;
  border-right: none;
  float: right;
  height: 116px;
  width: 74.8%;
  /* 719px */

  padding: 20px 0;
}
.content-variable-landing .var-content .sect-noticias {
  float: left;
  height: auto;
  margin-left: 1%;
  width: 60%;
}

.content-variable-landing .var-content .sect-noticias ul li {
  /*background: url("../../images/Pescadores/bullet-temas-interes.jpg") no-repeat left 5px;*/
  margin-bottom: 1em;
  padding-left: 10px;
  font-size: 11px;
}
.content-variable-landing .var-content .sect-noticias ul li a:hover {
  color: #b81927;
}
.content-variable-landing .var-content .simulador {
  float: left;
  padding-left: 20px;
  width: 58.4%;
  /* 420px */
}
.content-variable-landing .var-content .simulador .accion.seleccion {
  line-height: normal;
  margin-right: 0.4rem;
  max-width: 120px;
  min-width: 38px;
  padding: 0.5rem;
  vertical-align: middle;
}
.content-variable-landing .var-content .simulador ul {
  display: inline-block;
  width: 100%;
  margin-bottom: 25px;
}
.content-variable-landing .var-content .simulador li {
  float: left;
  color: #777777;
  font-size: 13px;
  line-height: 23px;
  margin-right: 30px;
}
.content-variable-landing .var-content .simulador li:last-child {
  margin-right: 0;
}
.content-variable-landing .var-content .simulador li.active {
  color: #222222;
  font-size: 1.4rem;
  font-family: 'open_sanssemibold';
  src: url('../../font/opensans-semibold-webfont.eot');
}
.content-variable-landing .var-content .simulador li.active span {
  background-position: left bottom;
  height: 26px;
  color: #f49600;
  font-weight: bold;
  line-height: 26px;
}
/*.content-variable-landing .var-content .simulador .accion:first-child {
  float: left;
}*/
/*.content-variable-landing .var-content .simulador .accion:last-child {
  float: right;
}*/
.content-variable-landing .var-content .simulador ul li span {
  /*background: url(../../images/Pescadores/paso-simulador-landing.png) left top no-repeat;*/
  color: #fff;
  float: left;
  height: 24px;
  line-height: 24px;
  text-align: center;
  width: 25px;
  margin-right: 3px;
}
.content-variable-landing .var-content .test {
  border-left: 1px solid #dddddd;
  float: right;
  padding-left: 20px;
  padding-right: 20px;
  width: 29.2%;
  /* 210px */

  /*height: 105px;*/
}
.content-variable-landing .var-content .test h5 {
  line-height: 35px;
  margin-bottom: 5px;
}
.content-variable-landing .var-content .test h5 img {
  float: left;
  margin-right: 5px;
}
.tipo-productos h2 {
  margin-bottom: 0.2em;
}
.tipo-productos h3 {
  color: #212121;
  /*font-size:30px;*/

  margin-bottom: 20px;
  font-family: open_sansregular;
}
/*.tipo-productos .bajada {
  margin-bottom: 2em;
}*/
.tipo-productos p {
  color: #202020;
  font-size: 16px;
  line-height: 20px;
}
.tipo-productos > ul {
  display: inline-block;
  /* margin-top: 1em;*/
  width: 100%;
}
.esp-tc .tipo-productos > h4 {
  margin-top: 3rem;
}
.tipo-productos > ul > li {
  /*background: url(../../images/Pescadores/bullet-productos.png) left 5px no-repeat;*/
  display: inline-block;
  padding-left: 15px;
  margin-top: 1.5rem;
  width: 97%;
  position: relative;
}
.tipo-productos > ul > li h4 {
  margin-bottom: 5px;
}
.tipo-productos > ul > li h4 a {
  color: #b81927;
}
.tipo-productos > ul > li a {
  line-height: 20px;
  display: block;
}
.tipo-productos > ul > li a.link-comparador {
  color: #bd2634;
  float: right;
  font-family: 'open_sanssemibold';
  clear: both;
  text-decoration: underline;
  position: relative;
  z-index: 1;
}
.tipo-productos > ul > li a:hover {
  color: #b91a28;
}
.tipo-productos > ul > li ul {
  margin-top: 1.5em;
}
.tipo-productos > ul > li ul li {
  margin-left: 1em;
  margin-bottom: 1em;
}
.tipo-productos > ul li:last-child {
  margin-bottom: 0;
}
/*.tipo-productos.hipotecario > ul {
  margin-top: 1em;
  margin-bottom: 3em;
}*/
.tipo-productos .content-tbl p {
  font-size: 1em;
}
.tipo-productos .content-tbl .productHeadings th {
  vertical-align: middle;
  padding-top: 0.5em;
}
.tipo-productos .content-tbl .productHeadings th h6 {
  display: block;
  min-height: 50px;
  vertical-align: middle;
}
.tipo-productos .content-tbl .leer-mas {
  display: block;
  font-size: 1em;
  line-height: 34px;
  height: 34px;
  margin-top: 1em;
  position: static;
  text-align: left;
  overflow: hidden;
}
.tipo-productos .content-tbl .leer-mas.btn-yellow {
  /*background: url("../../images/Pescadores/btn-mas-yellow.png") no-repeat right top;*/
}
.tipo-productos .content-tbl .leer-mas.btn-orange {
  /*background: url("../../images/Pescadores/btn-mas-orange.png") no-repeat right top;*/
}
.tipo-productos .content-tbl .leer-mas.btn-red {
  background: url('../../images/Pescadores/btn-mas-red.png') no-repeat right top;
}
.tipo-productos .content-tbl .leer-mas.btn-violet {
  /*background: url("../../images/Pescadores/btn-mas-violet.png") no-repeat right top;*/
}
.tipo-faq > ul {
  display: inline-block;
  margin-top: 2em;
  width: 100%;
}
.tipo-faq > ul > li {
  /*background: url(../../images/Pescadores/bullet-productos.png) left 5px no-repeat;*/
  padding-left: 15px;
  margin-bottom: 1em;
  line-height: normal;
}
.tipo-faq > ul > li a:hover {
  color: #b91a28;
}
.modulos-grales {
  background-color: #fff;
  padding: 10%;
  border: 1px #ccc solid;
  border-top: 2px #b91a28 solid;
  margin-bottom: 20px;
  position: relative;
  /*overflow:hidden;
    width:79.8%;*/
}
.modulos-grales.top-yellow {
  border-top: 2px #fa9600 solid !important;
}
.modulos-grales.top-red {
  border-top: 2px #b91a28 solid !important;
}
.modulos-grales.top-blue {
  border-top: 2px #00419a solid !important;
}
.modulos-grales.top-violet {
  border-top: 2px #5d3261 solid !important;
}
.modulos-grales.pre-aprob {
  background-color: #f4f4f4;
}
.modulos-grales.banner {
  border: 1px #ccc solid;
  padding: 0;
}
.modulos-grales.banner a {
  display: block;
  margin: 0;
}
.modulos-grales.banner a img {
  width: 100%;
}
.modulos-grales#modulo-videotut .leer-mas {
  background-color: #b91a28;
}
.modulos-grales#modulo-seguridad .leer-mas {
  background-color: #385079;
}
.modulos-grales.esp {
  padding-bottom: 6em;
}
.modulos-grales.decoration a {
  text-decoration: underline;
  display: inline;
}
.modulos-grales .title-box {
  margin-bottom: 0.5em;
}
.modulos-grales .title-box p {
  font-size: 1.077em;
}
.modulos-grales > a {
  display: block;
  margin-top: 0.8em;
}
.modulos-grales h2 {
  margin-bottom: 1em;
  text-align: center;
}
.modulos-grales h3 {
  margin-bottom: 1em;
}
.modulos-grales h6 {
  margin-bottom: 1em;
}
.modulos-grales > ul {
  /*margin-bottom:20px;*/
}
.modulos-grales > ul > li {
  margin-bottom: 1.5em;
}
.modulos-grales > ul > li > ul {
  margin-top: 0;
  margin-left: 10px;
}
.modulos-grales > ul > li > ul > li {
  list-style: none;
  margin-top: 5px;
  padding-left: 1.5em;
}
.modulos-grales > ul > li > ul > li a {
  color: #666;
  font-family: open_sansregular;
}
.modulos-grales > ul > li a {
  display: block;
  text-decoration: none;
  line-height: 18px;
}
.modulos-grales > ul > li a:hover {
  color: #b91a28;
}
.modulos-grales > ul > li p {
  margin-bottom: 0;
}
.modulos-grales > ul > li:last-child {
  margin-bottom: 0;
}
.modulos-grales.wbullet > ul > li {
  /*background: url(../../images/Pescadores/bullet-temas-interes.jpg) left 8px no-repeat;*/
  padding-left: 10px;
}
.modulos-grales h5 {
  margin-bottom: 10px;
}
.modulos-grales p {
  margin-bottom: 10px;
  line-height: 18px;
}
.modulos-grales#noticias-rel li {
  list-style: none;
  padding-left: 0;
}
.modulos-grales#modulo-rss {
  background-color: #eee;
  /*border-color: #ccc;*/

  text-align: center;
}
.modulos-grales#modulo-rss p {
  margin-top: 10px;
}
.modulos-grales.title {
  /*border-color: #ccc;*/

  padding: 0;
}
.modulos-grales.title h3 {
  margin: 0;
  padding: 5% 10%;
  display: block;
}
.modulos-grales.title > ul {
  padding-bottom: 1em;
}
.modulos-grales.title > ul li {
  margin: 0;
}
.modulos-grales.accord > ul > li {
  /*background: url("../../images/Pescadores/bullet-down.png") no-repeat 96% 0.8rem;*/
}
.modulos-grales.accord > ul > li.nodesp {
  background: none !important;
}
.modulos-grales.accord > ul > li.nodesp > a {
  color: #333 !important;
}
.modulos-grales.accord > ul > li.nodesp > a:hover {
  color: #b91a28 !important;
}
.modulos-grales.accord > ul > li > ul > li {
  /*background: url("../../images/Pescadores/bullet-round.png") no-repeat 11% 0.8rem;*/
}
.modulos-grales.accord > ul > li.open {
  background: url('../../images/Pescadores/bg-modulo-grales.jpg') repeat-x left
    top;
}
.modulos-grales.accord > ul > li.open > a {
  /*background: url("../../images/Pescadores/bullet-up.png") no-repeat 96% 0.8rem;*/
  color: #fff;
}
.modulos-grales.title > ul li a {
  padding: 3% 10%;
}
.modulos-grales.title > ul li a.active {
  background: url('../../images/Pescadores/bg-modulo-grales.jpg') repeat-x left
    bottom #f49600;
  color: #fff;
  text-decoration: none;
}
.modulos-grales.title#modulo-mini-noticias {
  background-color: #eee;
}
.modulos-grales.title#modulo-mini-noticias h6 {
  background-color: #ddd;
  color: #333;
}
.modulos-grales.title#todo-segmentos h6 {
  background-color: #b91a28;
}
.seleccion-opcion {
  display: none;
}
.title-contact {
  margin-bottom: 1em;
}
.title-contact p {
  font-size: 1.077em;
  font-family: open_sanssemibold;
}
#filtros .modulos-grales .subTitle {
  margin-bottom: 1em;
}
#filtros .modulos-grales .subTitle P {
  font-family: open_sanssemibold;
}
#filtros .modulos-grales ul {
  margin-bottom: 2em;
}
#dudas h3 {
  margin-bottom: 0;
  text-align: center;
}
#dudas h6 {
  margin-bottom: 1em;
  text-align: center;
}
#dudas .accion {
  margin-bottom: 1em;
}
#dudas ul.sociales {
  margin: 0 auto;
  overflow: hidden;
  width: 5em;
}
#dudas ul.sociales li {
  float: left;
  margin-right: 5px;
}
#dudas ul.sociales li a {
  background-image: url('../../images/Pescadores/icon-rd-footer.png');
  background-repeat: no-repeat;
  display: block;
  height: 27px;
  text-indent: -9999px;
  width: 28px;
}
#dudas ul.sociales li a.fb {
  background-position: -29px center;
}
#dudas ul.sociales li a.tw {
  background-position: 0 center;
}
#dudas ul.sociales li:last-child {
  margin-right: 0;
}
.bg-var-content {
  background-color: #f39800;
  height: 100%;
  left: 0;
  position: absolute;
  width: 27%;
  z-index: -1;
}
.bg-var-content-gris {
  background-color: #eee;
  /*  border-top: 1px solid #DDDDDD;
  border-bottom: 1px solid #DDDDDD;*/
  height: 100%;
  right: 0;
  position: absolute;
  width: 30%;
  z-index: -1;
}
/*============== FICHA PRODUCTO ==============*/
#head-ficha {
  margin: 0 0 30px;
}
#head-ficha.ficha {
  margin-top: 30px;
}
#head-ficha h2 {
  font-family: open_sansregular;
  margin-bottom: 20px;
}
#head-ficha p {
  margin-bottom: 20px;
}
#head-ficha .accion {
  margin-right: 20px;
}
.tools {
  display: table;
  margin-bottom: 0.5em;
}
.tools a {
  margin-right: 10px;
  font-size: 0.9em;
  text-decoration: underline;
}
.tools a:last-child {
  margin-right: 0;
}
.tools a:hover {
  color: #b91a28;
}
#grey-zone {
  background-color: #f1f3f2;
  /*margin-top:30px;*/

  padding: 30px 0 10px;
  position: relative;
  margin-bottom: 30px;
}
.cover-grey-zone {
  background-color: #f1f3f2;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}
/*============== SALA DE PRENSA ==============*/
#sala-prensa {
  margin-top: 1.3em;
}
#sala-prensa #noticias-dest {
  margin-bottom: 6em;
}
#sala-prensa #noticias-dest h2 {
  margin-bottom: 1em;
  font-family: open_sanslight;
}
#sala-prensa #noticias-grales h5,
#sala-prensa #noticias-grales h3 {
  margin-bottom: 1em;
}
#head-sala-prensa {
  border-bottom: 1px #ccc solid;
  margin-bottom: 2em;
  display: inline-block;
  width: 100%;
  padding-bottom: 2em;
}
#head-sala-prensa .buscador-sala-prensa {
  overflow: hidden;
  margin-bottom: 2em;
}
#head-sala-prensa .buscador-sala-prensa .search {
  float: left;
  margin-top: 0;
}
#head-sala-prensa .filtros {
  float: left;
  width: 30%;
  margin-right: 5%;
}
#head-sala-prensa > p {
  font-size: 1.3em;
  margin-bottom: 0.5em;
}
#banner-noticias {
  border-top: 2px #fa9600 solid;
  margin-bottom: 2em;
}
.flex-direction-nav {
  width: 100%;
  position: absolute;
  text-align: center;
  left: 35%;
  bottom: 0px;
}
.flex-direction-nav li {
  float: left;
  position: relative;
  opacity: 0.5;
  filter: alpha(opacity=50);
  margin: 0 1px;
}
.flex-direction-nav li:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}
#banner-noticias .carousel-control.left,
.flex-direction-nav li a.flex-prev {
  /*background: url("../../images/Pescadores/btn-next-prev.png") no-repeat 0 0;*/
  bottom: 0;
  height: 26px;
  left: 35%;
  top: auto;
  width: 35px;
  z-index: 5;
  text-indent: -999999px;
  display: block;
}
#banner-noticias .carousel-control.right,
.flex-direction-nav li a.flex-next {
  /*background: url("../../images/Pescadores/btn-next-prev.png") no-repeat right 0;*/
  bottom: 0;
  height: 26px;
  left: 40.5%;
  top: auto;
  width: 35px;
  z-index: 5;
  text-indent: -999999px;
  display: block;
}
#banner-noticias .carousel-inner .item {
  height: 365px;
  position: relative;
}
#banner-noticias .carousel-inner .item > img {
  width: 100%;
  min-width: 100%;
}
#banner-noticias .carousel-inner .item .carousel-caption {
  background-color: #fff;
  border: none;
  bottom: 0;
  left: 0;
  padding: 2em;
  top: 0;
  position: absolute;
  width: 230px;
  z-index: 1;
}
#banner-noticias .carousel-inner .item .carousel-caption .noticia-sala-prensa {
  border-bottom: none;
  display: block;
  margin: 0;
}
#banner-noticias
  .carousel-inner
  .item
  .carousel-caption
  .noticia-sala-prensa
  a
  .txt {
  width: auto;
}
.noticia-sala-prensa .txt.pdf {
  /*background: url("../../images/Pescadores/icon-pdf-corp.png") no-repeat 0 25px;*/
  display: block;
}
.noticia-sala-prensa .txt.pdf h4,
.noticia-sala-prensa .txt.pdf p {
  padding-left: 40px;
  margin-bottom: 0;
  width: auto;
}
/*============== DETALLE SALA DE PRENSA ==============*/
.detalle-noticia {
  margin-top: 30px;
}
.detalle-noticia h2 {
  font-family: open_sansregular;
  line-height: 40px;
  margin-bottom: 20px;
}
.detalle-noticia p {
  margin-bottom: 20px;
}
.detalle-noticia img {
  margin-bottom: 20px;
}
.noticias-rel ul li {
  padding: 0;
  background-image: none;
}
.noticias-rel ul li img {
  margin-bottom: 5px;
}
.noticias-rel span {
  display: block;
  margin-bottom: 5px;
  font-size: 11px;
}
.date-publ {
  display: block;
  margin-bottom: 10px;
}
#head-ficha .head-modulo {
  bottom: 25%;
  left: 60px;
  position: absolute;
  width: 30%;
}
.head-modulo {
  bottom: 25%;
  left: 2%;
  position: absolute;
  width: 30%;
  text-align: left;
}
.head-modulo h2,
.head-modulo p {
  color: #fff;
}
.head-modulo h2 {
  margin: 0 0 0.5em;
}
.head-modulo .bajada {
  margin: 0 0 2em;
}
/*============== PASOS SIMULADORES ==============*/
#simulador {
  margin-top: 10px;
}
ul.pasos {
  display: inline-block;
  margin-bottom: 20px;
  width: 100%;
}
ul.pasos li {
  background: #f3f3f3;
  color: #777777;
  float: left;
  padding: 0 5%;
  height: 50px;
  line-height: 50px;
  position: relative;
  z-index: 2;
  width: 23.3%;
}
ul.pasos li.selected {
  background: url('../../images/Pescadores/bg-pasos-simulador-selected.png')
    no-repeat right top;
  border-color: #fff;
  color: #fff;
  z-index: 3;
}
ul.pasos li.selected span {
  background-color: #fff;
  color: #f39800;
  font-weight: bold;
  padding: 0 5px;
}
ul.pasos li.selected span.deco-pasos {
  background: url('../../images/Pescadores/deco-pasos-simulador-right-selected.jpg')
    no-repeat right top;
}
ul.pasos li.selected:last-child {
  border-right: none;
}
ul.pasos li:first-child span.deco-pasos {
  display: none;
}
ul.pasos li:first-child.selected {
  border-left: none;
}
ul.pasos li span {
  background-color: #cccccc;
  color: #ffffff;
  font-size: 12px;
  margin-right: 10px;
  padding: 0 6px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
}
ul.pasos li span.deco-pasos {
  background: url('../../images/Pescadores/deco-pasos-simulador.png') no-repeat
    left top;
  border-radius: 0;
  height: 50px;
  margin: 0;
  padding: 0;
  position: absolute;
  left: -15px;
  top: 0;
  width: 23px;
}
/*============== NOTICIAS SALA DE PRENSA ==============*/
.noticia-sala-prensa {
  border-bottom: 1px #ccc solid;
  display: table;
  margin-bottom: 1.2em;
  padding-bottom: 2em;
}
.noticia-sala-prensa a {
  display: block;
  margin-bottom: 0.5em;
}
.noticia-sala-prensa a:hover,
.noticia-sala-prensa a:hover h4 {
  color: #b91a28 !important;
}
.noticia-sala-prensa a img {
  margin: 0 0 1em 1em;
  float: right;
  width: 40%;
}
.noticia-sala-prensa a img.left-img {
  float: left;
  width: 20%;
  margin: 0 1em 1em 0;
}
.noticia-sala-prensa a .txt h4 {
  float: none;
  /*font-size: 1.538em;  */

  line-height: 25px;
  width: 100%;
  margin-bottom: 0.8em;
  color: #000;
}
.noticia-sala-prensa a .txt p {
  float: none;
  width: 100%;
}
.noticia-sala-prensa.destacada a {
  display: block;
}
.noticia-sala-prensa.destacada a img {
  float: none;
  width: 100%;
  margin-right: 0;
}
.noticia-sala-prensa.destacada a .txt {
  float: none;
  width: 100%;
}
.noticia-sala-prensa.destacada a .txt h4 {
  float: left;
  width: 50%;
}
.noticia-sala-prensa.destacada a .txt p {
  float: right;
  width: 45%;
}
span.date {
  margin-right: 10px;
  font-size: 1em;
}
span.tag {
  color: #5d3261;
  padding: 2px 0;
  font-size: 0.846em;
  /* 11px */

  font-family: open_sansbold;
  text-transform: uppercase;
}
/*============== RESULTADO BUSQUEDA ==============*/
#resultados-busqueda {
  margin-top: 2em;
}
#resultados-busqueda #resultados h3 {
  color: #b91a28;
  margin-bottom: 1em;
}
#resultados-busqueda #resultados .list li {
  list-style: disc inside;
  margin-bottom: 1em;
}
#resultados-busqueda #resultados a:hover,
#resultados-busqueda #resultados .list li a:hover {
  color: #b91a28;
}
#resultados-busqueda #resultados .video-tutoriales {
  display: table;
  margin-bottom: 2em;
}
#resultados-busqueda #resultados .video-tutoriales img {
  width: 23%;
}
#resultados-busqueda #resultados .video-tutoriales .txt {
  width: 75%;
  float: right;
}
#resultados-busqueda #resultados .head-buscador .search {
  width: 100%;
}
#resultados-busqueda #resultados .head-buscador #head-sala-prensa {
  margin-top: 2em;
}
#resultados-busqueda #resultados .head-buscador h3 {
  color: #333;
  margin: 0;
}
/*============== BASE FORMULARIOS ==============*/
.form-general {
  display: table;
  margin-bottom: 20px;
  width: 93%;
  vertical-align: middle;
}
.form-general label {
  float: left;
  width: 30%;
  margin-right: 0%;
  margin-top: 3px;
  position: relative;
}
label.alto {
  margin-top: 0.2em;
}

.form-general label + label {
  margin-right: 5%;
  width: auto;
}
.form-general input[type='text'],
.form-general input[type='password'],
.form-general input[type='tel'] {
  display: inline;
  width: 57%;
  margin-right: 1%;
}
.form-general input[type='text'].input-phone,
.form-general input[type='password'].input-phone,
.form-general input[type='tel'].input-phone {
  width: 32%;
}
.form-general.date input[type='text'],
.form-general.date input[type='tel'] {
  width: 15%;
  margin-right: 1%;
  /*padding: 1.6%;*/
}
.form-general.date input[type='text']:last-child,
.form-general.date input[type='tel']:last-child {
  margin-right: 0;
}
.form-general a.tooltip-help,
.form-general a.tooltip-help-pers {
  display: inline;
  /*position: absolute;
    right: 0;
    top: 10px;*/
  z-index: 9999;
}
.form-general .variation {
  margin: 0 1% 0% 0;
  width: 60.7%;
  float: left;
  height: 44px;
}
.form-general .dia {
  width: 17%;
}
.form-general .mes {
  width: 28%;
}
.form-general .ano {
  width: 18%;
}
.form-general.vertical label {
  float: none;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 5px;
  display: block;
  width: 100%;
}
.form-general.vertical input[type='text'],
.form-general.vertical input[type='tel'] {
  width: 89%;
}
.form-general.vertical input[type='text'].input-phone,
.form-general.vertical input[type='tel'].input-phone {
  margin: 0;
  width: 64%;
}
.form-general.vertical .variation {
  width: 93.5%;
}
.form-general.vertical .error-txt {
  margin-left: 0;
}
.form-general.vertical .has-feedback .form-control-feedback {
  top: 25px;
}
.form-general.vertical.date input[type='text'],
.form-general.vertical.date input[type='tel'] {
  margin-right: 1%;
  width: 25.5%;
}
.form-general.vertical label + label {
  margin-right: 5%;
  width: auto;
  display: inline;
}
table.resumen {
  margin-bottom: 2em;
}
.m-bottom {
  margin-bottom: 1em;
}
.llamado {
  margin: 2em 0;
}
.llamado h2 {
  margin-bottom: 10px;
}
.notas p {
  color: #666;
}
.btn-acciones {
  /*display: inline-block;
  width: 100%;*/
  padding-top: 1em;
  margin: auto 35%;
  text-align: center;
}
.btn-acciones.align {
  width: 88.5%;
}
.btn-acciones a.accion {
  /*float: right;*/
  margin-left: 0.5em;
}
.btn-acciones.middle a.accion {
  float: none;
}
.btn-acciones.middle a.accion:first-child {
  margin-bottom: 0.5em;
}
.tooltip-pers {
  background-color: #f6f6f6;
  border: 1px #ddd solid;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  box-shadow: 0 0 5px #ccc;
  moz-box-shadow: 0 0 5px #ccc;
  -webkit-box-shadow: 0 0 5px #ccc;
  display: none;
  left: 22px;
  padding: 10px;
  position: absolute;
  right: -270px;
  top: -20px;
  z-index: 9999;
  font-size: 12px;
  cursor: default;
}
.tooltip-pers span {
  display: block;
  margin-bottom: 5px;
}
.tooltip-pers span.bullet {
  /*background: url("../../images/Pescadores/bullet-tooltip.png") no-repeat right top;*/
  height: 16px;
  left: -13px;
  position: absolute;
  top: 20px;
  width: 13px;
}
.tooltip-help-pers {
  position: relative;
}
.tooltip-help-pers:hover .tooltip-pers {
  display: block;
}
/*============== FAQ ==============*/
#faq {
  margin-top: 1.3em;
}
#faq h2 {
  font-family: open_sanslight;
  margin-bottom: 25px;
}
#faq #acordiones h3 {
  color: #b91a28;
  margin-bottom: 10px;
}
#faq #acordiones .panel-group {
  margin-bottom: 50px;
}
#faq #head-faq {
  /*border-bottom: 1px solid #ccc;*/

  display: inline-block;
  margin-bottom: 2em;
  padding-bottom: 2em;
  width: 100%;
}
#faq #head-faq h4 {
  margin: 0 0 0.8em;
}
#faq #head-faq p {
  float: left;
  line-height: 40px;
  margin: 0 0.5em;
}
#faq #head-faq .buscador-faq {
  float: left;
  clear: left;
  width: 450px;
}
#faq #head-faq .buscador-faq .search {
  margin: 0;
  width: 100%;
}
#faq #head-faq .buscador-faq .search input[type='text'] {
  height: 32px;
  line-height: 32px;
}
#faq #head-faq .buscador-faq .search .btn {
  top: 11px;
}
#faq #head-faq .filtros {
  float: left;
  margin: 0 0.5em 0 0;
}
#faq #head-faq .accion {
  float: left;
}
#faq .select-answ {
  margin: 1em 0 3em;
}
#faq .select-answ h3 {
  margin-bottom: 0.5em;
}
.navbar-toggle {
  border-radius: 4px;
  left: 0;
  top: 15px;
  padding: 9px 10px;
  position: absolute;
}
.sr-only {
  border: 0 none;
  clip: rect(0px, 0px, 0px, 0px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.navbar-toggle .icon-bar {
  background-color: #70777d;
  border-radius: 1px;
  display: block;
  height: 2px;
  width: 22px;
}
.navbar-toggle {
  display: none;
}
/* SELECT PERSONZALIDOS */
div.variation {
  position: relative;
  height: 42px;
}
div.variation:hover span.select,
div.variation:active span.select {
  background-position: right bottom;
}
div.variation select.select {
  height: 42px;
  line-height: 42px;
  margin: 0;
  padding: 0;
  padding-left: 10px;
  position: relative;
  width: 100%;
  z-index: 1;
}
div.variation select.select:focus,
div.variation select.select:hover {
  border-color: #ff6702;
}
div.variation select {
  background: url('../../images/Pescadores/bg_select.png') no-repeat scroll
    right top #ffffff;
  border: 1px #ccc solid;
  bottom: 0;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  cursor: default;
  font-size: 12px;
  height: 40px;
  left: 0;
  padding-left: 10px;
  line-height: 40px;
  padding-right: 20%;
  position: absolute;
  text-indent: 0px;
  overflow: hidden;
  width: 100%;
  z-index: 2;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

div.variation select::-ms-expand {
  display: none;
}

#head-serv {
  margin-bottom: 2em;
  padding-top: 2rem;
}
#head-serv .txt-llamado {
  margin-bottom: 1rem;
}
#head-serv .buscador-categorias {
  background-color: #f8f8f8;
  display: inline-block;
  width: 100%;
}
#head-serv .buscador-categorias .cover-search {
  background-color: #aaa;
  display: inline-block;
  padding: 2% 4%;
  width: 92%;
}
#head-serv .buscador-categorias .cover-search .search {
  float: left;
  margin: 0;
  width: 55%;
}
#head-serv .categorias-serv {
  display: inline-block;
  padding: 2% 4%;
  width: 92%;
}
#head-serv .categorias-serv h3 {
  margin-bottom: 2rem;
}
#head-serv .categorias-serv > ul {
  float: left;
  margin-bottom: 1rem;
  margin-right: 4%;
  width: 21%;
}
#head-serv .categorias-serv > ul li {
  /*list-style: disc inside;*/
  margin-bottom: 0.9rem;
  padding-left: 15px;
}
#head-serv .categorias-serv > ul li:before {
  content: '\2022';
  font-size: 19px;
  font-family: Arial, sans-serif;
  display: inline-block;
  margin-left: -12px;
  margin-right: 5px;
}
#head-serv .categorias-serv > ul li.titleCategory {
  font-family: 'open_sanssemibold';
  font-size: 1.125rem;
  list-style: none;
  margin-bottom: 1rem;
}
#head-serv .categorias-serv > ul li.titleCategory:before {
  content: '';
}
#head-serv .categorias-serv > ul li a {
  color: #333;
  font-size: 0.875rem;
}
#head-serv .categorias-serv > ul li a:hover {
  color: #b21f25;
}
#head-serv .sociales {
  background-color: #fff;
  display: inline-block;
  width: 95%;
  padding: 10% 0 3% 5%;
}
#head-serv .sociales span {
  line-height: 1.3em;
  display: block;
  font-size: 1.2em;
  margin-bottom: 0.5em;
}
#head-serv .sociales ul {
  float: left;
}
#head-serv .sociales ul li {
  float: left;
  margin-right: 15px;
}
#head-serv .sociales ul li:last-child {
  margin-right: 0;
}
#head-serv .sociales ul li a {
  /*background-image: url(../../images/Pescadores/icon-rd-large.png);*/
  background-repeat: no-repeat;
  display: block;
  width: 36px;
  height: 35px;
  text-indent: -9999px;
}
#head-serv .sociales ul li a.tw {
  background-position: 0 center;
}
#head-serv .sociales ul li a.fb {
  background-position: -36px center;
}
#head-serv .sociales ul li a.rss {
  background-position: -72px center;
}
#head-serv .info-serv {
  background-color: #fff;
  display: inline-block;
  width: 95%;
  padding: 5% 0 10% 5%;
}
#head-serv .info-serv span {
  line-height: 1.3em;
  display: block;
  font-size: 1.2em;
  margin-bottom: 0.5em;
}
#head-serv .info-serv ul {
  display: inline-block;
  width: 100%;
}
#head-serv .info-serv ul li {
  display: block;
  font-size: 0.923em;
  margin-bottom: 5px;
}
#head-serv .grid {
  margin-bottom: 0;
}
#head-serv .article-ico.serv {
  display: block;
  float: none;
  overflow: hidden;
  text-align: left;
  padding: 0;
}
#head-serv .article-ico.serv:first-child {
  margin-top: 2em;
}
#head-serv .article-ico.serv [class^='icon-'],
#head-serv .article-ico.serv [class*=' icon-'] {
  float: left;
  margin: 0;
}
#head-serv .article-ico.serv h2 {
  font-family: open_sanslight;
}
#tarifas {
  margin-bottom: 1em;
  padding-top: 1rem;
}
#tarifas .m-bottom {
  margin-bottom: 2em;
}
#tarifas .categorias-serv > ul {
  display: block;
  margin-bottom: 2rem;
  margin-right: 4%;
  width: 100%;
}
#tarifas .categorias-serv > ul li {
  list-style: disc inside;
  margin-bottom: 0.9rem;
  padding-left: 20px;
}
#tarifas .categorias-serv > ul li.titleCategory {
  font-family: 'open_sanssemibold';
  font-size: 1.125rem;
  list-style: none;
  margin-bottom: 1rem;
  padding-left: 0;
}
#tarifas .categorias-serv > ul li a {
  color: #333;
  font-size: 0.875rem;
}
#tarifas .categorias-serv > ul li a:hover {
  color: #b21f25;
}
/* LOGIN */
#login {
  margin-top: 40px;
  /*h2{
        font-family:@font-regular;
    }*/
}
#login #log-section {
  background-color: #f9f9f9;
  padding: 2em 8%;
}

#login #log-section h2 {
  font-size: 2.5em;
}

#login #log-section #content-login {
  margin: 20px 0 30px;
}
#login #log-section #content-login .nav-tabs {
  display: inline-block;
  width: 100%;
}
#login #log-section #content-login .nav-tabs li {
  background-color: #dedede;
  width: 40%;
  margin-right: 0;
}
#login #log-section #content-login .nav-tabs li.active {
  border: 1px #cfcfcf solid;
  border-bottom: none;
  background-color: #fff;
  width: 40%;
}
#login #log-section #content-login .nav-tabs li.active a {
  color: #333;
}
#login #log-section #content-login .nav-tabs li a {
  color: #999;
  font-size: 1.231em;
  font-family: open_sansregular;
  text-align: center;
}
#login #log-section #content-login .nav-tabs > li.active > a,
#login #log-section #content-login .nav-tabs > li.active > a:hover,
#login #log-section #content-login .nav-tabs > li.active > a:focus {
  background-color: #fff;
}
#login #log-section #content-login .tab-content {
  background-color: #fff;
  border: 1px #cfcfcf solid;
  margin-top: 0;
  padding-top: 8%;
  width: 93.7%;
}
#login #log-section #content-login .tab-content .form-general {
  float: left;
  width: 65%;
}
#login #log-section #content-login .tab-content .logo-veris {
  float: left;
}
#login #log-section #content-login .tab-content .accion {
  float: left;
  min-width: 25%;
}
#login .section-ayuda h6 {
  margin-bottom: 1em;
}
#login .section-ayuda ul {
  display: inline-block;
  margin: 0.5em 0;
  width: 100%;
}
#login .section-ayuda ul li {
  /*background: url("../../images/Pescadores/bullet-temas-interes.jpg") no-repeat left 5px;*/
  margin-bottom: 1em;
  padding-left: 1em;
}
#login .section-ayuda ul li a:hover {
  color: #b91a28;
}
#login #consejos-seguridad {
  padding-top: 2em;
  padding-left: 5%;
}
#login #consejos-seguridad h3,
#login #consejos-seguridad p {
  margin-bottom: 1.5em;
}
#login #consejos-seguridad p {
  /*background: url("../../images/Pescadores/bullet-temas-interes.jpg") no-repeat left 7px;*/
  padding-left: 15px;
}
/* CONSULTA ESTADO DE SOLICITUD */

.bajada_captcha {
  clear: both;
  font-size: 10px;
  font-style: italic;
  color: gray;
}

#consulta {
  margin-top: 10px;
  /*h2{
        font-family:@font-regular;
    }*/
}
#consulta #log-section {
  background-color: #f9f9f9;
  padding: 2em 8%;
}

#consulta #log-section h2 {
  font-size: 2.5em;
}

#consulta #log-section #content-login {
  margin: 20px 0 30px;
  max-width: 90%;
}
#consulta #log-section #content-login .nav-tabs {
  display: inline-block;
  width: 100%;
}
#consulta #log-section #content-login .nav-tabs li {
  background-color: #dedede;
  width: 40%;
  margin-right: 0;
}
#consulta #log-section #content-login .nav-tabs li.active {
  border: 1px #cfcfcf solid;
  border-bottom: none;
  background-color: #fff;
  width: 40%;
}
#consulta #log-section #content-login .nav-tabs li.active a {
  color: #333;
}
#consulta #log-section #content-login .nav-tabs li a {
  color: #999;
  font-size: 1.231em;
  font-family: open_sansregular;
  text-align: center;
}
#consulta #log-section #content-login .nav-tabs > li.active > a,
#consulta #log-section #content-login .nav-tabs > li.active > a:hover,
#consulta #log-section #content-login .nav-tabs > li.active > a:focus {
  background-color: #fff;
}
#consulta #log-section #content-login .tab-content {
  background-color: #fff;
  border: 1px #cfcfcf solid;
  margin-top: 0;
  padding-top: 8%;
  width: 93.7%;
}
#consulta #log-section #content-login .tab-content .form-general {
  float: left;
  width: 65%;
}
#consulta #log-section #content-login .tab-content .logo-veris {
  float: left;
}
#consulta #log-section #content-login .tab-content .accion {
  float: left;
  min-width: 25%;
}
#consulta .section-ayuda h6 {
  margin-bottom: 1em;
}
#consulta .section-ayuda ul {
  display: inline-block;
  margin: 0.5em 0;
  width: 100%;
}
#consulta .section-ayuda ul li {
  /*background: url("../../images/Pescadores/bullet-temas-interes.jpg") no-repeat left 5px;*/
  margin-bottom: 1em;
  padding-left: 1em;
}
#consulta .section-ayuda ul li a:hover {
  color: #b91a28;
}
#consulta #consejos-seguridad {
  padding-top: 2em;
  padding-left: 5%;
}
#consulta #consejos-seguridad h3,
#consulta #consejos-seguridad p {
  margin-bottom: 1.5em;
}
#consulta #consejos-seguridad p {
  /*background: url("../../images/Pescadores/bullet-temas-interes.jpg") no-repeat left 7px;*/
  padding-left: 15px;
}
#consulta .tab-content {
  border-top: 1px solid #e5e5e5;
  margin-top: -1px;
  background-color: #fff;
  width: 94%;
  display: inline-block;
  padding: 8%;
}
/* LANDING PRODUCTO TARJETAS CREDITO */
.tbl-productos {
  margin-top: 2em;
}
#productTable {
  float: left;
  padding: 15px 0 25px;
  width: 100%;
  /* 940px */
}
.tbl-respond table {
  margin-bottom: 2em;
}
#productComparison,
#fixed-table-header {
  border-collapse: separate;
  clear: both;
  width: 100%;
  /* 940px */
}
#tc {
  padding-top: 1rem;
}
#fixed-table-header {
  z-index: 1111;
}
.productComparison tr.productHeadings,
.productComparison tr.productLinks {
  background-color: #ffffff;
}
.productComparison tr.productHeadings .accion {
  margin-top: 0.5rem;
}
.productComparison td {
  color: #333333;
}
.productComparison th {
  color: #000000;
  font-weight: normal;
  line-height: 20px;
  padding: 0.5rem 0;
}
.productHeadings th,
.rowContent th {
  border-right: 1px solid #ddd;
  text-align: center;
}
.productHeadings img,
.rowContent img {
  max-width: 35%;
}
.productHeadings .bord-yell,
.rowContent .bord-yell {
  border-top: 2px #fa9600 solid;
  text-align: center;
  padding-top: 0.5em;
}
.productHeadings .bord-yell h3,
.rowContent .bord-yell h3 {
  padding-top: 0.5em;
}
.productHeadings .bord-red,
.rowContent .bord-red {
  border-top: 2px #b91a28 solid;
  text-align: center;
  padding-top: 0.5em;
}
.productHeadings .bord-red h3,
.rowContent .bord-red h3 {
  padding-top: 0.5em;
}
.productHeadings .bord-orange,
.rowContent .bord-orange {
  border-top: 2px #d75814 solid;
  text-align: center;
  padding-top: 0.5em;
}
.productHeadings .bord-orange h3,
.rowContent .bord-orange h3 {
  padding-top: 0.5em;
}
.productHeadings .bord-blue,
.rowContent .bord-blue {
  border-top: 2px #5d3261 solid;
  text-align: center;
  padding-top: 0.5em;
}
.productHeadings .bord-blue h3,
.rowContent .bord-blue h3 {
  padding-top: 0.5em;
}
#productComparison tr.rowContent:last-child td.bord-bottom-yell {
  border-bottom: 2px #fa9600 solid;
}
#productComparison tr.rowContent:last-child td.bord-bottom-red {
  border-bottom: 2px #b91a28 solid;
}
#productComparison tr.rowContent:last-child td.bord-bottom-orange {
  border-bottom: 2px #d75814 solid;
}
#productComparison tr.rowContent:last-child td.bord-bottom-blue {
  border-bottom: 2px #5d3261 solid;
}
/*.productComparison tr.productHeadings td, .productComparison tr.productHeadings th {
    padding: 18px 20px 7px;
}*/
.productComparison tr.productHeadings td {
  background-color: #ffffff !important;
  color: #ffffff;
}
.productComparison tr.productLinks td {
  padding: 0 20px 10px;
}
#productComparison tr.rowContent {
  background-color: #e4e4e4;
}
#productComparison tr.rowContent:last-child td {
  border-bottom: 2px #ddd solid;
}
#productComparison tr.rowContent td {
  border-right: 1px solid #ddd;
  padding: 20px 20px;
}
.productComparison tr.rowContent th {
  padding: 8px 25px 20px;
  vertical-align: middle;
}
.productComparison tr .even,
.productComparison tr.productLinks .odd {
  background-color: #f6f6f6;
}
.productComparison tr .odd,
.productComparison tr.productLinks .even {
  background-color: #eee;
}
.productComparison tr.bgRow {
  height: 4px !important;
}
#productComparison tr.rows-separator {
  height: 2px !important;
}
.productComparison tr.bgRow td,
.productComparison tr.rows-separator td {
  background-color: #ddd;
  font-size: 1px;
  height: 1px;
  line-height: 1%;
  padding: 0;
}
#productComparison tr.sectionType th,
#productComparison tr.sectionType td {
  padding: 13px 0;
}
#productComparison tr.sectionType a.expand {
  color: #000000;
  display: block;
  font-size: 125%;
  font-weight: bold;
  padding: 5px 0 3px 35px;
  white-space: nowrap;
}
.productComparison tr ul {
  padding: 0 0 0 2px;
}
#productComparison tr ul li,
#fixed-table-header tr ul li {
  color: #333333;
  display: inline;
  float: left;
  font-size: 75%;
  line-height: 16px;
  padding: 0 7px 7px 0;
}
#productComparison th h3,
#fixed-table-header th h3 {
  line-height: 16px;
  padding: 1em 0 10px 2px;
}
#productComparison tr.productHeadings.megaHead th,
#fixed-table-header tr.productHeadings.megaHead th {
  border-bottom: 1px solid #ddd;
}
#productComparison tr.productHeadings.megaHead th h3,
#fixed-table-header tr.productHeadings.megaHead th h3 {
  color: #1f1f1f;
  font-size: 2rem;
  line-height: normal;
  padding: 1rem 0;
}
#productComparison th .accion,
#fixed-table-header th .accion {
  margin: 0.5em auto;
  min-width: 35%;
  width: 35%;
}
#productComparison th h3 span,
#fixed-table-header th h3 span {
  background-position: left 2px;
  display: block;
  font-size: 125%;
  line-height: 20px;
}
#productComparison td p,
#productComparison th p {
  line-height: 16px;
  padding: 0;
}
#productComparison td p a,
#productComparison th p a {
  color: #b81926;
  font-family: 'open_sanssemibold';
  text-decoration: underline;
}
#productComparison td > a,
#productComparison th > a {
  display: block;
  margin-bottom: 5px;
}
#productComparison td > a:hover,
#productComparison th > a:hover {
  text-decoration: underline;
}
#productComparison td.center p,
#productComparison th.center p {
  text-align: center;
}
/*#productComparison td p strong {
  font-size: 133.33%;
  line-height: 20px;
}*/
.productComparison tr ul li a,
#productComparison td a.more {
  cursor: pointer;
  padding: 0 11px 0 0;
}
#productComparison td a.more {
  text-decoration: none;
}
#productComparison td .yes,
#productComparison td .no,
#grid .grid .contentItem table tr td p.yes,
#grid .grid .contentItem table tr td p.no {
  height: 20px;
  padding: 18px 0 0;
  text-indent: -9999px;
}
#grid .grid .contentItem table tr td p.yes,
#grid .grid .contentItem table tr td p.no {
  padding: 0;
}
/* MENSAJES GENÉRICOS */
#mensaje {
  padding: 10em 5em;
}
#mensaje h3 {
  font-family: open_sanssemibold;
  margin-bottom: 0.5em;
  text-align: center;
}
#mensaje .btn-acciones {
  margin: 2em auto 0;
  width: 100%;
  text-align: center;
}
/* SUCURSALES */
#head-sucursales {
  margin-top: 2em;
}
#selector-ag label {
  display: block;
  margin-bottom: 0.5em;
  width: 100%;
}
#selector-ag label input {
  margin: 0 0.5em 0 0;
}
.mensaje_negativo {
  display: none;
}
.barra_navegacion {
  width: 100%;
  display: inline-block;
  margin: 2em 0 0 0;
  padding: 0;
}
.barra_navegacion h3 {
  margin-bottom: 0.5em;
}
.buscar_sucursal {
  width: 99.7%;
  height: 29px;
  float: left;
  border: solid 1px #ccc;
  border-radius: 5px;
  margin: 0 0 1em 0;
  background: none;
  transition: all 0.3s;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
}
.buscar_sucursal input[type='text'] {
  background-color: none;
  border: none;
  float: left;
  height: 29px;
  line-height: 20px;
  padding: 0 3%;
  width: 94%;
}
.boton_buscar_sucursal {
  border: none;
  width: 143px;
  height: 29px;
  float: right;
  display: block;
  margin: 0;
  padding: 0 0 0 30px;
  color: #fff;
  cursor: pointer;
  font-size: 13px;
}
.boton_buscar_sucursal:hover {
  /*background: url(../../images/Pescadores/boton_buscar_sucursales.png) left bottom no-repeat;*/
}
.box0 {
  display: inline-block;
  width: 100%;
  height: auto;
}
.box1 {
  overflow: hidden;
  clear: both;
  float: left;
  margin: 18px 0 0 0;
  border-top: solid 5px #ccc;
  padding: 14px 0 0 0;
  width: 100%;
}
.box1 .variation {
  margin-bottom: 1em;
  display: inline-block;
  width: 100%;
}
.focus {
  border: solid 1px #ff6702;
}
.leyenda1 {
  width: 106px;
  height: 75px;
  float: left;
  /*background: url(../../images/Pescadores/leyenda1.png) top center no-repeat;*/
  margin: 14px 0 0 0;
}
.leyenda2 {
  width: 106px;
  height: 75px;
  float: left;
  /*background: url(../../images/Pescadores/leyenda2.png) top center no-repeat;*/
  margin: 14px 0 0 0;
  border-left: solid 1px #ccc;
}
.leyenda1 p,
.leyenda2 p {
  text-align: center;
  margin: 62px 0 0 0;
}
.bloque_de_info {
  width: 940px;
  height: auto;
  overflow: hidden;
  float: left;
  margin: 15px 0 0 0;
  display: none;
}
.titulo_info {
  margin: 15px 0 0 0;
  height: 21px;
  font-size: 15px;
  font-weight: bold;
  color: #766a62;
  float: left;
  padding: 0 0 0 25px;
  /*background: url(../../images/Pescadores/icono_titulo_info.png) left center no-repeat;*/
}
.info_left {
  float: left;
  width: 602px;
  height: auto;
  overflow: hidden;
}
.info_left ul {
  clear: both;
  overflow: hidden;
}
.info_left li {
  width: 602px;
  height: auto;
  overflow: hidden;
  float: left;
  list-style: none;
  border-bottom: solid 1px #ccc;
  line-height: 20px;
  padding: 10px 0 10px 35px;
  font-size: 12px;
}
.info_left li:first-child {
  border-top: solid 1px #ccc;
  margin: 33px 0 0 0;
}
.info_nombre {
  /*background: url(../../images/Pescadores/icono_direccion.png) no-repeat;*/
  background-position: 1% 10px;
}
.info_direccion {
  /*background: url(../../images/Pescadores/icono_direccion.png) no-repeat;*/
  background-position: 1% 10px;
}
.info_locales {
  /*background: url(../../images/Pescadores/icono_tienda.png) no-repeat;*/
  background-position: 1% 10px;
}
.info_telefono {
  /*background: url(../../images/Pescadores/icono_persona.png) no-repeat;*/
  background-position: 1% 10px;
}
.info_estacionamiento {
  /*background: url(../../images/Pescadores/icono_car.png) no-repeat;*/
  background-position: 1% 10px;
}
/*caja servicios*/
.contenedor_servicios {
  width: 288px;
  height: 400px;
  float: left;
  margin: 0 0 0 50px;
}
.contenedor_resultados {
  width: 282px;
  float: right;
  background: #f1f1f1;
  height: 353px;
  overflow: hidden;
  border-radius: 3px;
  position: relative;
}
.resultadosLista {
  width: 92%;
  float: left;
  position: absolute;
  height: 80%;
  overflow: hidden;
  right: 4px;
  top: 50px;
  padding: 1%;
}
.contenedor_resultados h3 {
  height: 21px;
  font-size: 15px;
  font-weight: bold;
  color: #766a62;
  float: left;
  padding: 0 0 0 25px;
  /*background: url(../../images/Pescadores/icono_info.png) left center no-repeat;*/
  text-shadow: 0 1px 0 #ffffff;
  margin: 20px 0 0 14px;
}
.contenedor_resultados .resultadosLista li {
  padding: 6px 18px 6px 19px;
  border-bottom: 1px solid #ddd;
  box-shadow: 0 1px 0 0 #fff;
  font-size: 11px;
  /*background: url(../../images/Pescadores/bullet_check_orange.png) left center no-repeat;*/
}
.contenedor_resultados .resultadosLista li:hover {
  padding: 6px 18px 6px 19px;
  color: #fff;
  /*background: url(../../images/Pescadores/bullet_check_white.png) left center no-repeat #cccccc;*/
  cursor: pointer;
}
.contenedor_resultados .resultadosLista li:last-child {
  border-bottom: none;
  box-shadow: none;
}
/* globo */
.globo .contenedor {
  width: 214px;
  height: 118px;
  margin: 7px 0 0 33px;
  position: absolute;
}
.globo .contenedor h1 {
  font-size: 12px !important;
  color: #e05206 !important;
  font-weight: bold !important;
  margin: 0 0 7px 0;
}
.globo .contenedor p {
  color: #766a62;
  font-weight: 100;
}
.globo .contenedor span.direccion {
  font-weight: bold;
  font-size: 12px;
  color: #766a62;
  margin: 0 0 7px 0 !important;
}
.globo .contenedor p.tenemos {
  font-size: 12px;
  font-weight: bold !important;
  color: #e05206;
  margin: 5px 0 0 0;
}
.globo .contenedor p.horario_tienda {
  font-size: 11px;
}
.globo .contenedor p.horario_kiosco {
  font-size: 11px;
}
.mensaje_negativo {
  color: red;
}
.mas_info {
  position: absolute;
  top: 108px;
  right: 28px;
}
.mas_info a {
  color: #e05206;
  font-weight: bold;
}
.mas_info a:hover {
  color: #ccc;
}
#content-map-canvas {
  margin-top: 2em;
}
#map-canvas img {
  width: 100%;
  max-width: none;
}
/* HAZTE CLIENTE */
#contenedor.hazte-cliente {
  margin-top: 2em;
}
#contenedor.hazte-cliente ul.pasos li {
  width: 40%;
}
.title-line.back {
  margin-bottom: 0.8em;
}
.title-line.back h2,
.title-line.back h3 {
  padding: 5px 10px;
}
.title-line.back h2 {
  font-family: open_sanslight;
}
.title-line.back h3 {
  font-family: open_sanssemibold;
}
.bajada.center {
  margin-bottom: 3em;
}
#solicitud-producto h3,
#solicitud-producto h6 {
  margin-bottom: 1em;
}
#solicitud-producto #tab-productos {
  display: inline-block;
  margin-bottom: 2em;
  width: 100%;
}
#solicitud-producto #tab-productos .selector-productos .checkbox {
  display: inline-block;
  margin-bottom: 2em;
  width: 100%;
  margin-right: 5%;
}
#solicitud-producto #tab-productos .nav-tabs > li.active > a,
#solicitud-producto #tab-productos .nav-tabs > li.active > a:hover,
#solicitud-producto #tab-productos .nav-tabs > li.active > a:focus {
  background-color: #fa9600;
  color: #fff;
}
#solicitud-producto #tab-productos .tab-content {
  padding: 2% 10%;
  width: 55%;
}
.phone {
  float: left;
  width: 20%;
  margin-right: 5%;
}
.phone .variation {
  width: 100%;
}
.error-txt {
  color: #b91a28;
  float: left;
  margin-top: 0.5em;
}
input.error-txt {
  margin-left: 0%;
}
.input-error-txt {
  color: #b91a28;
  margin-left: 23%;
}
/* JQUERY UI */
/*! jQuery UI - v1.10.4 - 2014-02-27
* http://jqueryui.com
* Includes: jquery.ui.core.css, jquery.ui.autocomplete.css, jquery.ui.datepicker.css, jquery.ui.menu.css, jquery.ui.theme.css
* To view and modify this theme, visit http://jqueryui.com/themeroller/
* Copyright 2014 jQuery Foundation and other contributors; Licensed MIT */
/* Layout helpers
----------------------------------*/
.ui-helper-hidden {
  display: none;
}
.ui-helper-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.ui-helper-reset {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  line-height: 1.3;
  text-decoration: none;
  font-size: 100%;
  list-style: none;
}
.ui-helper-clearfix:before,
.ui-helper-clearfix:after {
  content: '';
  display: table;
  border-collapse: collapse;
}
.ui-helper-clearfix:after {
  clear: both;
}
.ui-helper-clearfix {
  min-height: 0;
  /* support: IE7 */
}
.ui-helper-zfix {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0;
  filter: alpha(opacity=0);
}
.ui-front {
  z-index: 100;
}
/* Interaction Cues
----------------------------------*/
.ui-state-disabled {
  cursor: default !important;
}
/* Icons
----------------------------------*/
/* states and images */
.ui-icon {
  display: block;
  text-indent: -99999px;
  overflow: hidden;
  background-repeat: no-repeat;
}
/* Misc visuals
----------------------------------*/
/* Overlays */
.ui-widget-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.ui-autocomplete {
  position: absolute;
  top: 0;
  left: 0;
  cursor: default;
}
.ui-datepicker {
  width: 17em;
  padding: 0.2em 0.2em 0;
  display: none;
}
.ui-datepicker .ui-datepicker-header {
  position: relative;
  padding: 0.2em 0;
}
.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
  position: absolute;
  top: 2px;
  width: 1.8em;
  height: 1.8em;
}
.ui-datepicker .ui-datepicker-prev-hover,
.ui-datepicker .ui-datepicker-next-hover {
  top: 1px;
}
.ui-datepicker .ui-datepicker-prev {
  left: 2px;
}
.ui-datepicker .ui-datepicker-next {
  right: 2px;
}
.ui-datepicker .ui-datepicker-prev-hover {
  left: 1px;
}
.ui-datepicker .ui-datepicker-next-hover {
  right: 1px;
}
.ui-datepicker .ui-datepicker-prev span,
.ui-datepicker .ui-datepicker-next span {
  display: block;
  position: absolute;
  left: 50%;
  margin-left: -8px;
  top: 50%;
  margin-top: -8px;
}
.ui-datepicker .ui-datepicker-title {
  margin: 0 2.3em;
  line-height: 1.8em;
  text-align: center;
}
.ui-datepicker .ui-datepicker-title select {
  font-size: 1em;
  margin: 1px 0;
}
.ui-datepicker select.ui-datepicker-month,
.ui-datepicker select.ui-datepicker-year {
  width: 49%;
}
.ui-datepicker table {
  width: 100%;
  font-size: 0.9em;
  border-collapse: collapse;
  margin: 0 0 0.4em;
}
.ui-datepicker th {
  padding: 0.7em 0.3em;
  text-align: center;
  font-weight: bold;
  border: 0;
}
.ui-datepicker td {
  border: 0;
  padding: 1px;
}
.ui-datepicker td span,
.ui-datepicker td a {
  display: block;
  padding: 0.2em;
  text-align: right;
  text-decoration: none;
}
.ui-datepicker .ui-datepicker-buttonpane {
  background-image: none;
  margin: 0.7em 0 0 0;
  padding: 0 0.2em;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
}
.ui-datepicker .ui-datepicker-buttonpane button {
  float: right;
  margin: 0.5em 0.2em 0.4em;
  cursor: pointer;
  padding: 0.2em 0.6em 0.3em 0.6em;
  width: auto;
  overflow: visible;
}
.ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current {
  float: left;
}
/* with multiple calendars */
.ui-datepicker.ui-datepicker-multi {
  width: auto;
}
.ui-datepicker-multi .ui-datepicker-group {
  float: left;
}
.ui-datepicker-multi .ui-datepicker-group table {
  width: 95%;
  margin: 0 auto 0.4em;
}
.ui-datepicker-multi-2 .ui-datepicker-group {
  width: 50%;
}
.ui-datepicker-multi-3 .ui-datepicker-group {
  width: 33.3%;
}
.ui-datepicker-multi-4 .ui-datepicker-group {
  width: 25%;
}
.ui-datepicker-multi .ui-datepicker-group-last .ui-datepicker-header,
.ui-datepicker-multi .ui-datepicker-group-middle .ui-datepicker-header {
  border-left-width: 0;
}
.ui-datepicker-multi .ui-datepicker-buttonpane {
  clear: left;
}
.ui-datepicker-row-break {
  clear: both;
  width: 100%;
  font-size: 0;
}
/* RTL support */
.ui-datepicker-rtl {
  direction: rtl;
}
.ui-datepicker-rtl .ui-datepicker-prev {
  right: 2px;
  left: auto;
}
.ui-datepicker-rtl .ui-datepicker-next {
  left: 2px;
  right: auto;
}
.ui-datepicker-rtl .ui-datepicker-prev:hover {
  right: 1px;
  left: auto;
}
.ui-datepicker-rtl .ui-datepicker-next:hover {
  left: 1px;
  right: auto;
}
.ui-datepicker-rtl .ui-datepicker-buttonpane {
  clear: right;
}
.ui-datepicker-rtl .ui-datepicker-buttonpane button {
  float: left;
}
.ui-datepicker-rtl .ui-datepicker-buttonpane button.ui-datepicker-current,
.ui-datepicker-rtl .ui-datepicker-group {
  float: right;
}
.ui-datepicker-rtl .ui-datepicker-group-last .ui-datepicker-header,
.ui-datepicker-rtl .ui-datepicker-group-middle .ui-datepicker-header {
  border-right-width: 0;
  border-left-width: 1px;
}
.ui-menu {
  list-style: none;
  padding: 2px;
  margin: 0;
  display: block;
  outline: none;
}
.ui-menu .ui-menu {
  margin-top: -3px;
  position: absolute;
}
.ui-menu .ui-menu-item {
  margin: 0;
  padding: 0;
  width: 100%;
  /* support: IE10, see #8844 */

  list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);
}
.ui-menu .ui-menu-divider {
  margin: 5px -2px 5px -2px;
  height: 0;
  font-size: 0;
  line-height: 0;
  border-width: 1px 0 0 0;
}
.ui-menu .ui-menu-item a {
  text-decoration: none;
  display: block;
  padding: 2px 0.4em;
  line-height: 1.5;
  min-height: 0;
  /* support: IE7 */

  font-weight: normal;
}
.ui-menu .ui-menu-item a.ui-state-focus,
.ui-menu .ui-menu-item a.ui-state-active {
  font-weight: normal;
  margin: -1px;
}
.ui-menu .ui-state-disabled {
  font-weight: normal;
  margin: 0.4em 0 0.2em;
  line-height: 1.5;
}
.ui-menu .ui-state-disabled a {
  cursor: default;
}
/* icon support */
.ui-menu-icons {
  position: relative;
}
.ui-menu-icons .ui-menu-item a {
  position: relative;
  padding-left: 2em;
}
/* left-aligned */
.ui-menu .ui-icon {
  position: absolute;
  top: 0.2em;
  left: 0.2em;
}
/* right-aligned */
.ui-menu .ui-menu-icon {
  position: static;
  float: right;
}
/* Component containers
----------------------------------*/
.ui-widget {
  font-family: Verdana, Arial, sans-serif;
  font-size: 1.1em;
}
.ui-widget .ui-widget {
  font-size: 1em;
}
.ui-widget input,
.ui-widget select,
.ui-widget textarea,
.ui-widget button {
  font-family: Verdana, Arial, sans-serif;
  font-size: 1em;
}
.ui-widget-content {
  border: 1px solid #aaaaaa;
  /*background: #ffffff url(images/ui-bg_flat_75_ffffff_40x100.png) 50% 50% repeat-x;*/
  color: #222222;
}
.ui-widget-content a {
  color: #222222;
}
.ui-widget-header {
  border: 1px solid #aaaaaa;
  /*background: #cccccc url(images/ui-bg_highlight-soft_75_cccccc_1x100.png) 50% 50% repeat-x;*/
  color: #222222;
  font-weight: bold;
}
.ui-widget-header a {
  color: #222222;
}
/* Interaction states
----------------------------------*/
.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
  border: 1px solid #d3d3d3;
  /*background: #e6e6e6 url(images/ui-bg_glass_75_e6e6e6_1x400.png) 50% 50% repeat-x;*/
  font-weight: normal;
  color: #555555;
}
.ui-state-default a,
.ui-state-default a:link,
.ui-state-default a:visited {
  color: #555555;
  text-decoration: none;
}
.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus {
  border: 1px solid #999999;
  /*background: #dadada url(images/ui-bg_glass_75_dadada_1x400.png) 50% 50% repeat-x;*/
  font-weight: normal;
  color: #212121;
}
.ui-state-hover a,
.ui-state-hover a:hover,
.ui-state-hover a:link,
.ui-state-hover a:visited,
.ui-state-focus a,
.ui-state-focus a:hover,
.ui-state-focus a:link,
.ui-state-focus a:visited {
  color: #212121;
  text-decoration: none;
}
.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active {
  border: 1px solid #aaaaaa;
  /*background: #ffffff url(images/ui-bg_glass_65_ffffff_1x400.png) 50% 50% repeat-x;*/
  font-weight: normal;
  color: #212121;
}
.ui-state-active a,
.ui-state-active a:link,
.ui-state-active a:visited {
  color: #212121;
  text-decoration: none;
}
/* Interaction Cues
----------------------------------*/
.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight {
  border: 1px solid #fcefa1;
  /*background: #fbf9ee url(images/ui-bg_glass_55_fbf9ee_1x400.png) 50% 50% repeat-x;*/
  color: #363636;
}
.ui-state-highlight a,
.ui-widget-content .ui-state-highlight a,
.ui-widget-header .ui-state-highlight a {
  color: #363636;
}
.ui-state-error,
.ui-widget-content .ui-state-error,
.ui-widget-header .ui-state-error {
  border: 1px solid #cd0a0a;
  /*background: #fef1ec url(images/ui-bg_glass_95_fef1ec_1x400.png) 50% 50% repeat-x;*/
  color: #cd0a0a;
}
.ui-state-error a,
.ui-widget-content .ui-state-error a,
.ui-widget-header .ui-state-error a {
  color: #cd0a0a;
}
.ui-state-error-text,
.ui-widget-content .ui-state-error-text,
.ui-widget-header .ui-state-error-text {
  color: #cd0a0a;
}
.ui-priority-primary,
.ui-widget-content .ui-priority-primary,
.ui-widget-header .ui-priority-primary {
  font-weight: bold;
}
.ui-priority-secondary,
.ui-widget-content .ui-priority-secondary,
.ui-widget-header .ui-priority-secondary {
  opacity: 0.7;
  filter: alpha(opacity=70);
  font-weight: normal;
}
.ui-state-disabled,
.ui-widget-content .ui-state-disabled,
.ui-widget-header .ui-state-disabled {
  opacity: 0.35;
  filter: alpha(opacity=35);
  background-image: none;
}
.ui-state-disabled .ui-icon {
  filter: alpha(opacity=35);
  /* For IE8 - See #6059 */
}
/* Icons
----------------------------------*/
/* states and images */
.ui-icon {
  width: 16px;
  height: 16px;
}
.ui-icon,
.ui-widget-content .ui-icon {
  /*background-image: url(images/ui-icons_222222_256x240.png);*/
}
.ui-widget-header .ui-icon {
  /*background-image: url(images/ui-icons_222222_256x240.png);*/
}
.ui-state-default .ui-icon {
  /*background-image: url(images/ui-icons_888888_256x240.png);*/
}
.ui-state-hover .ui-icon,
.ui-state-focus .ui-icon {
  /*background-image: url(images/ui-icons_454545_256x240.png);*/
}
.ui-state-active .ui-icon {
  /*background-image: url(images/ui-icons_454545_256x240.png);*/
}
.ui-state-highlight .ui-icon {
  /*background-image: url(images/ui-icons_2e83ff_256x240.png);*/
}
.ui-state-error .ui-icon,
.ui-state-error-text .ui-icon {
  /*background-image: url(images/ui-icons_cd0a0a_256x240.png);*/
}
/* positioning */
.ui-icon-blank {
  background-position: 16px 16px;
}
.ui-icon-carat-1-n {
  background-position: 0 0;
}
.ui-icon-carat-1-ne {
  background-position: -16px 0;
}
.ui-icon-carat-1-e {
  background-position: -32px 0;
}
.ui-icon-carat-1-se {
  background-position: -48px 0;
}
.ui-icon-carat-1-s {
  background-position: -64px 0;
}
.ui-icon-carat-1-sw {
  background-position: -80px 0;
}
.ui-icon-carat-1-w {
  background-position: -96px 0;
}
.ui-icon-carat-1-nw {
  background-position: -112px 0;
}
.ui-icon-carat-2-n-s {
  background-position: -128px 0;
}
.ui-icon-carat-2-e-w {
  background-position: -144px 0;
}
.ui-icon-triangle-1-n {
  background-position: 0 -16px;
}
.ui-icon-triangle-1-ne {
  background-position: -16px -16px;
}
.ui-icon-triangle-1-e {
  background-position: -32px -16px;
}
.ui-icon-triangle-1-se {
  background-position: -48px -16px;
}
.ui-icon-triangle-1-s {
  background-position: -64px -16px;
}
.ui-icon-triangle-1-sw {
  background-position: -80px -16px;
}
.ui-icon-triangle-1-w {
  background-position: -96px -16px;
}
.ui-icon-triangle-1-nw {
  background-position: -112px -16px;
}
.ui-icon-triangle-2-n-s {
  background-position: -128px -16px;
}
.ui-icon-triangle-2-e-w {
  background-position: -144px -16px;
}
.ui-icon-arrow-1-n {
  background-position: 0 -32px;
}
.ui-icon-arrow-1-ne {
  background-position: -16px -32px;
}
.ui-icon-arrow-1-e {
  background-position: -32px -32px;
}
.ui-icon-arrow-1-se {
  background-position: -48px -32px;
}
.ui-icon-arrow-1-s {
  background-position: -64px -32px;
}
.ui-icon-arrow-1-sw {
  background-position: -80px -32px;
}
.ui-icon-arrow-1-w {
  background-position: -96px -32px;
}
.ui-icon-arrow-1-nw {
  background-position: -112px -32px;
}
.ui-icon-arrow-2-n-s {
  background-position: -128px -32px;
}
.ui-icon-arrow-2-ne-sw {
  background-position: -144px -32px;
}
.ui-icon-arrow-2-e-w {
  background-position: -160px -32px;
}
.ui-icon-arrow-2-se-nw {
  background-position: -176px -32px;
}
.ui-icon-arrowstop-1-n {
  background-position: -192px -32px;
}
.ui-icon-arrowstop-1-e {
  background-position: -208px -32px;
}
.ui-icon-arrowstop-1-s {
  background-position: -224px -32px;
}
.ui-icon-arrowstop-1-w {
  background-position: -240px -32px;
}
.ui-icon-arrowthick-1-n {
  background-position: 0 -48px;
}
.ui-icon-arrowthick-1-ne {
  background-position: -16px -48px;
}
.ui-icon-arrowthick-1-e {
  background-position: -32px -48px;
}
.ui-icon-arrowthick-1-se {
  background-position: -48px -48px;
}
.ui-icon-arrowthick-1-s {
  background-position: -64px -48px;
}
.ui-icon-arrowthick-1-sw {
  background-position: -80px -48px;
}
.ui-icon-arrowthick-1-w {
  background-position: -96px -48px;
}
.ui-icon-arrowthick-1-nw {
  background-position: -112px -48px;
}
.ui-icon-arrowthick-2-n-s {
  background-position: -128px -48px;
}
.ui-icon-arrowthick-2-ne-sw {
  background-position: -144px -48px;
}
.ui-icon-arrowthick-2-e-w {
  background-position: -160px -48px;
}
.ui-icon-arrowthick-2-se-nw {
  background-position: -176px -48px;
}
.ui-icon-arrowthickstop-1-n {
  background-position: -192px -48px;
}
.ui-icon-arrowthickstop-1-e {
  background-position: -208px -48px;
}
.ui-icon-arrowthickstop-1-s {
  background-position: -224px -48px;
}
.ui-icon-arrowthickstop-1-w {
  background-position: -240px -48px;
}
.ui-icon-arrowreturnthick-1-w {
  background-position: 0 -64px;
}
.ui-icon-arrowreturnthick-1-n {
  background-position: -16px -64px;
}
.ui-icon-arrowreturnthick-1-e {
  background-position: -32px -64px;
}
.ui-icon-arrowreturnthick-1-s {
  background-position: -48px -64px;
}
.ui-icon-arrowreturn-1-w {
  background-position: -64px -64px;
}
.ui-icon-arrowreturn-1-n {
  background-position: -80px -64px;
}
.ui-icon-arrowreturn-1-e {
  background-position: -96px -64px;
}
.ui-icon-arrowreturn-1-s {
  background-position: -112px -64px;
}
.ui-icon-arrowrefresh-1-w {
  background-position: -128px -64px;
}
.ui-icon-arrowrefresh-1-n {
  background-position: -144px -64px;
}
.ui-icon-arrowrefresh-1-e {
  background-position: -160px -64px;
}
.ui-icon-arrowrefresh-1-s {
  background-position: -176px -64px;
}
.ui-icon-arrow-4 {
  background-position: 0 -80px;
}
.ui-icon-arrow-4-diag {
  background-position: -16px -80px;
}
.ui-icon-extlink {
  background-position: -32px -80px;
}
.ui-icon-newwin {
  background-position: -48px -80px;
}
.ui-icon-refresh {
  background-position: -64px -80px;
}
.ui-icon-shuffle {
  background-position: -80px -80px;
}
.ui-icon-transfer-e-w {
  background-position: -96px -80px;
}
.ui-icon-transferthick-e-w {
  background-position: -112px -80px;
}
.ui-icon-folder-collapsed {
  background-position: 0 -96px;
}
.ui-icon-folder-open {
  background-position: -16px -96px;
}
.ui-icon-document {
  background-position: -32px -96px;
}
.ui-icon-document-b {
  background-position: -48px -96px;
}
.ui-icon-note {
  background-position: -64px -96px;
}
.ui-icon-mail-closed {
  background-position: -80px -96px;
}
.ui-icon-mail-open {
  background-position: -96px -96px;
}
.ui-icon-suitcase {
  background-position: -112px -96px;
}
.ui-icon-comment {
  background-position: -128px -96px;
}
.ui-icon-person {
  background-position: -144px -96px;
}
.ui-icon-print {
  background-position: -160px -96px;
}
.ui-icon-trash {
  background-position: -176px -96px;
}
.ui-icon-locked {
  background-position: -192px -96px;
}
.ui-icon-unlocked {
  background-position: -208px -96px;
}
.ui-icon-bookmark {
  background-position: -224px -96px;
}
.ui-icon-tag {
  background-position: -240px -96px;
}
.ui-icon-home {
  background-position: 0 -112px;
}
.ui-icon-flag {
  background-position: -16px -112px;
}
.ui-icon-calendar {
  background-position: -32px -112px;
}
.ui-icon-cart {
  background-position: -48px -112px;
}
.ui-icon-pencil {
  background-position: -64px -112px;
}
.ui-icon-clock {
  background-position: -80px -112px;
}
.ui-icon-disk {
  background-position: -96px -112px;
}
.ui-icon-calculator {
  background-position: -112px -112px;
}
.ui-icon-zoomin {
  background-position: -128px -112px;
}
.ui-icon-zoomout {
  background-position: -144px -112px;
}
.ui-icon-search {
  background-position: -160px -112px;
}
.ui-icon-wrench {
  background-position: -176px -112px;
}
.ui-icon-gear {
  background-position: -192px -112px;
}
.ui-icon-heart {
  background-position: -208px -112px;
}
.ui-icon-star {
  background-position: -224px -112px;
}
.ui-icon-link {
  background-position: -240px -112px;
}
.ui-icon-cancel {
  background-position: 0 -128px;
}
.ui-icon-plus {
  background-position: -16px -128px;
}
.ui-icon-plusthick {
  background-position: -32px -128px;
}
.ui-icon-minus {
  background-position: -48px -128px;
}
.ui-icon-minusthick {
  background-position: -64px -128px;
}
.ui-icon-close {
  background-position: -80px -128px;
}
.ui-icon-closethick {
  background-position: -96px -128px;
}
.ui-icon-key {
  background-position: -112px -128px;
}
.ui-icon-lightbulb {
  background-position: -128px -128px;
}
.ui-icon-scissors {
  background-position: -144px -128px;
}
.ui-icon-clipboard {
  background-position: -160px -128px;
}
.ui-icon-copy {
  background-position: -176px -128px;
}
.ui-icon-contact {
  background-position: -192px -128px;
}
.ui-icon-image {
  background-position: -208px -128px;
}
.ui-icon-video {
  background-position: -224px -128px;
}
.ui-icon-script {
  background-position: -240px -128px;
}
.ui-icon-alert {
  background-position: 0 -144px;
}
.ui-icon-info {
  background-position: -16px -144px;
}
.ui-icon-notice {
  background-position: -32px -144px;
}
.ui-icon-help {
  background-position: -48px -144px;
}
.ui-icon-check {
  background-position: -64px -144px;
}
.ui-icon-bullet {
  background-position: -80px -144px;
}
.ui-icon-radio-on {
  background-position: -96px -144px;
}
.ui-icon-radio-off {
  background-position: -112px -144px;
}
.ui-icon-pin-w {
  background-position: -128px -144px;
}
.ui-icon-pin-s {
  background-position: -144px -144px;
}
.ui-icon-play {
  background-position: 0 -160px;
}
.ui-icon-pause {
  background-position: -16px -160px;
}
.ui-icon-seek-next {
  background-position: -32px -160px;
}
.ui-icon-seek-prev {
  background-position: -48px -160px;
}
.ui-icon-seek-end {
  background-position: -64px -160px;
}
.ui-icon-seek-start {
  background-position: -80px -160px;
}
/* ui-icon-seek-first is deprecated, use ui-icon-seek-start instead */
.ui-icon-seek-first {
  background-position: -80px -160px;
}
.ui-icon-stop {
  background-position: -96px -160px;
}
.ui-icon-eject {
  background-position: -112px -160px;
}
.ui-icon-volume-off {
  background-position: -128px -160px;
}
.ui-icon-volume-on {
  background-position: -144px -160px;
}
.ui-icon-power {
  background-position: 0 -176px;
}
.ui-icon-signal-diag {
  background-position: -16px -176px;
}
.ui-icon-signal {
  background-position: -32px -176px;
}
.ui-icon-battery-0 {
  background-position: -48px -176px;
}
.ui-icon-battery-1 {
  background-position: -64px -176px;
}
.ui-icon-battery-2 {
  background-position: -80px -176px;
}
.ui-icon-battery-3 {
  background-position: -96px -176px;
}
.ui-icon-circle-plus {
  background-position: 0 -192px;
}
.ui-icon-circle-minus {
  background-position: -16px -192px;
}
.ui-icon-circle-close {
  background-position: -32px -192px;
}
.ui-icon-circle-triangle-e {
  background-position: -48px -192px;
}
.ui-icon-circle-triangle-s {
  background-position: -64px -192px;
}
.ui-icon-circle-triangle-w {
  background-position: -80px -192px;
}
.ui-icon-circle-triangle-n {
  background-position: -96px -192px;
}
.ui-icon-circle-arrow-e {
  background-position: -112px -192px;
}
.ui-icon-circle-arrow-s {
  background-position: -128px -192px;
}
.ui-icon-circle-arrow-w {
  background-position: -144px -192px;
}
.ui-icon-circle-arrow-n {
  background-position: -160px -192px;
}
.ui-icon-circle-zoomin {
  background-position: -176px -192px;
}
.ui-icon-circle-zoomout {
  background-position: -192px -192px;
}
.ui-icon-circle-check {
  background-position: -208px -192px;
}
.ui-icon-circlesmall-plus {
  background-position: 0 -208px;
}
.ui-icon-circlesmall-minus {
  background-position: -16px -208px;
}
.ui-icon-circlesmall-close {
  background-position: -32px -208px;
}
.ui-icon-squaresmall-plus {
  background-position: -48px -208px;
}
.ui-icon-squaresmall-minus {
  background-position: -64px -208px;
}
.ui-icon-squaresmall-close {
  background-position: -80px -208px;
}
.ui-icon-grip-dotted-vertical {
  background-position: 0 -224px;
}
.ui-icon-grip-dotted-horizontal {
  background-position: -16px -224px;
}
.ui-icon-grip-solid-vertical {
  background-position: -32px -224px;
}
.ui-icon-grip-solid-horizontal {
  background-position: -48px -224px;
}
.ui-icon-gripsmall-diagonal-se {
  background-position: -64px -224px;
}
.ui-icon-grip-diagonal-se {
  background-position: -80px -224px;
}
/* Misc visuals
----------------------------------*/
/* Corner radius */
.ui-corner-all,
.ui-corner-top,
.ui-corner-left,
.ui-corner-tl {
  border-top-left-radius: 4px;
}
.ui-corner-all,
.ui-corner-top,
.ui-corner-right,
.ui-corner-tr {
  border-top-right-radius: 4px;
}
.ui-corner-all,
.ui-corner-bottom,
.ui-corner-left,
.ui-corner-bl {
  border-bottom-left-radius: 4px;
}
.ui-corner-all,
.ui-corner-bottom,
.ui-corner-right,
.ui-corner-br {
  border-bottom-right-radius: 4px;
}
/* Overlays */
.ui-widget-overlay {
  /*background: #aaaaaa url(images/ui-bg_flat_0_aaaaaa_40x100.png) 50% 50% repeat-x;*/
  opacity: 0.3;
  filter: alpha(opacity=30);
}
.ui-widget-shadow {
  margin: -8px 0 0 -8px;
  padding: 8px;
  /*background: #aaaaaa url(images/ui-bg_flat_0_aaaaaa_40x100.png) 50% 50% repeat-x;*/
  opacity: 0.3;
  filter: alpha(opacity=30);
  border-radius: 8px;
}
/* MAPA DEL SITIO */
#site-map a {
  text-decoration: underline;
}
#site-map {
  margin-top: 2em;
}
#site-map h3 {
  margin-bottom: 1em;
}
#site-map .modulos-grales {
  margin: 0;
}
#site-map .modulos-grales.personas {
  border-top: 2px #fa9600 solid;
}
#site-map .modulos-grales.empresas {
  border-top: 2px #b91a28 solid;
}
#site-map .modulos-grales ul li > ul li {
  /*background: url("../../images/Pescadores/bullet-temas-interes.jpg") no-repeat left 7px;*/
  padding-left: 10px;
}
#site-map .modulos-grales ul li > ul li a {
  color: #333;
}
/* CAJA VECINA */
#site-side {
  margin-top: 3em;
  margin-bottom: 5em;
}
#site-side .article-img {
  display: block;
  margin: 0 10em 0 0;
}
#site-side.servi-estado h2 {
  margin-bottom: 1em;
}
#site-side h2 {
  margin-bottom: 0.4em;
}
#site-side h3 {
  margin-bottom: 1em;
}
#site-side .article-img p {
  min-height: 70px;
}

.banner-esp {
  background-color: #f1f3f2;
  border-bottom: 4px #a91736 solid;
  margin: 2em 0 4em;
  padding: 3% 9%;
}
.banner-esp .grid {
  margin: 0;
}
.banner-esp .grid h3 {
  margin: 0.3em 0 0 0 !important;
}
/* LANDING GENERICO */
#campana {
  margin-top: 1.5em;
}
#campana .detalle-campana {
  padding-top: 1em;
}
#campana .detalle-campana .title-line {
  background-image: none;
  margin-bottom: 1.5em;
  text-align: center;
}
#campana .detalle-campana .title-line h2,
#campana .detalle-campana .title-line h3 {
  font-size: 2.615em;
  line-height: 1em;
}
#campana .detalle-campana .bajada {
  margin-bottom: 1.5em;
  text-align: center;
}
#campana .detalle-campana .bajada h3 {
  color: #b91a28;
}
#campana .detalle-campana .bajada h3 span {
  font-family: open_sanssemibold;
}
#campana .detalle-campana .dest p {
  font-family: open_sanssemibold;
}
.legal {
  margin: 20px auto;
}
#campana .detalle-campana .legal small,
.legal small {
  font-family: open_sansregular;
  font-size: 11px;
  line-height: 14px;
  margin: 5px 0;
  display: block;
  color: #666;
}
#campana .detalle-campana p {
  margin-bottom: 1.5em;
  text-align: center;
}
.dest p {
  font-family: open_sanssemibold;
}
[class^='icon-'],
[class*=' icon-'] {
  display: inline-block;
  width: 16px;
  height: 16px;
  line-height: 16px;
  vertical-align: text-top;
  background-position: 16px 16px;
  background-repeat: no-repeat;
  margin-top: 1px;
  background: green;
}
.icon-large {
  width: 76px;
  height: 76px;
  line-height: 16px;
  vertical-align: text-top;
  background-position: 16px 16px;
}
/* EJEMPLOS DE ICONOS */
.icon-help {
  background: url('../../images/Pescadores/icon-help.png') no-repeat right top;
}
.icon-error {
  /*background: url("../../images/Pescadores/icon-warning.png") no-repeat center center;*/
}
.icon-ok {
  /*background: url("../../images/Pescadores/icon-ok.png") no-repeat center center;*/
}
.icon-home {
  /*background: url("../../images/Pescadores/icon-home.png") no-repeat center center;*/
}
/* ICONOS HOME  */
.icon-proyectos {
  /*background: url("../../images/Pescadores/icon-proyectos.png") no-repeat center center;*/
}
.icon-finanzas {
  /*background: url("../../images/Pescadores/icon-finanzas.png") no-repeat center center;*/
}
.icon-compras {
  /*background: url("../../images/Pescadores/icon-compras.png") no-repeat center center;*/
}
.icon-ahorro {
  /*background: url("../../images/Pescadores/icon-ahorro.png") no-repeat center center;*/
}
.icon-rentabilidad {
  /*background: url("../../images/Pescadores/icon-rentabilidad.png") no-repeat center center;*/
}
.icon-seguros {
  /*background: url("../../images/Pescadores/icon-seguros.png") no-repeat center center;*/
}
/* ICONOS MICROEMPRESAS  */
.icon-m-financiamiento {
  /*background: url("../../images/Pescadores/icon-m-financiamiento.png") no-repeat center center;*/
}
.icon-m-invertir {
  /*background: url("../../images/Pescadores/icon-m-invertir.png") no-repeat center center;*/
}
.icon-m-proteger {
  /*background: url("../../images/Pescadores/icon-m-proteger.png") no-repeat center center;*/
}
.icon-m-enviaDinero {
  /*background: url("../../images/Pescadores/icon-m-enviaDinero.png") no-repeat center center;*/
}
.icon-m-ayudaProyectos {
  background: url('../../images/Pescadores/icon-m-ayudaProyectos.png') no-repeat
    center center;
}
.icon-m-finanzas {
  /*background: url("../../images/Pescadores/icon-m-finanzas.png") no-repeat center center;*/
}
/* ICONOS VARIOS */
.icon-login {
  /*background: url("../../images/Pescadores/icon-login.png") no-repeat center top;*/
}
.icon-corp {
  /*background: url("../../images/Pescadores/icon-corp.jpg") no-repeat center top;*/
}
.icon-mesa-central {
  /*background: url("../../images/Pescadores/icon-mesa-ayuda.png") no-repeat center top;*/
  margin-top: 5px;
}
.icon-envio-email {
  /*background: url("../../images/Pescadores/icon-mail.png") no-repeat center top;*/
}
.icon-print {
  background: url('../../images/Pescadores/icon-print.png') no-repeat center top;
}
.ui-datepicker-trigger {
  /*background: url("../../images/Pescadores/icon-calendar.png") no-repeat center center;*/
  height: 43px;
  position: absolute;
  right: 6.9%;
  text-indent: -9999px;
  top: 0;
  width: 30px;
}
.icon-red-atencion {
  background: url('../../images/Pescadores/icon-red-atencion.png') no-repeat
    center -15px;
}
.icon-red-atencion-large {
  background: url('../../images/Pescadores/icon-red-atencion-large.png')
    no-repeat center -15px;
}
.icon-contactanos {
  /*background: url("../../images/Pescadores/icon-contactanos.png") no-repeat center -15px;*/
}
.icon-contactanos-large {
  /*background: url("../../images/Pescadores/icon-contactanos-large.png") no-repeat center -15px;*/
}
.icon-phone-large {
  /*background: url("../../images/Pescadores/icon-atencion-phone-large.png") no-repeat center -15px;*/
}
.icon-redes-large {
  /*background: url("../../images/Pescadores/icon-redes-large.png") no-repeat center -15px;*/
}
.icon-video {
  /*background: url("../../images/Pescadores/icon-video.png") no-repeat center top;*/
}
.icon-audio {
  /*background: url("../../images/Pescadores/icon-audio.png") no-repeat center top;*/
}
.icon-pdf {
  /*background: url("../../images/Pescadores/icon-pdf.png") no-repeat center top;*/
}
.icon-rss {
  /*background: url("../../images/Pescadores/icon-rss.png") no-repeat center top;*/
}
.icon-ingreso {
  /*background: url("../../images/Pescadores/icon-ingreso.png") no-repeat center top;*/
}
.icon-rel-prod {
  /*background: url("../../images/Pescadores/icon-prod-rel.png") no-repeat center top;*/
  height: 44px;
  width: 40px;
}
.icon-i-controlDinero {
  /*background: url("../../images/Pescadores/icon-control-dinero.png") no-repeat center top;*/
}
.icon-compras-small {
  /*background: url("../../images/Pescadores/icon-compras-small.png") no-repeat center top;*/
  width: 38px;
  height: 40px;
}
/*COLORS*/
/*TIPOGRAFIAS*/
/**/
/* 960px */
.bg {
  background: #ddd;
}
#biblioteca .title {
  margin-top: 20px;
  color: #d75814;
  margin-bottom: 20px;
}
#biblioteca th {
  font-size: 14px;
  padding: 10px 5px;
  font-family: open_sanssemibold;
}
#biblioteca tr {
  border-bottom: 1px solid #ddd;
}
#biblioteca td {
  padding: 5px;
}
/*============== FOOTER ABAJO ==============*/
html,
body {
  height: 100%;
}
/*#general 	{ margin: 0 auto -124px; }*/
#footer {
  height: 140px;
}
/*============== BASE ==============*/
body {
  font-family: open_sansregular;
  font-size: 13px;
  line-height: 14px;
}
body,
a {
  color: #333333;
}
a {
  text-decoration: none;
}
p {
  line-height: 22px;
}
.bajada {
  margin-bottom: 1rem;
}
.bajada p {
  font-size: 1.231em;
  /* 16px */

  line-height: 22px;
}
.bajada.center {
  text-align: center;
}
img {
  max-width: 100%;
  height: auto;
}
::-webkit-input-placeholder {
  font-style: italic;
}
:-moz-placeholder {
  /* Firefox 18- */

  font-style: italic;
}
::-moz-placeholder {
  /* Firefox 19+ */

  font-style: italic;
}
:-ms-input-placeholder {
  font-style: italic;
}
label input[type='radio'] {
  margin: 0 5px 0 0;
}
.pull-left {
  float: left;
}
.pull-right {
  float: right;
}
.animate *,
.accion {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.active-site {
  background: #fff;
}
.active-site a {
  color: #222222 !important;
  font-family: open_sanssemibold;
}
/*============== TIPOGRAFIA ==============*/
@font-face {
  font-family: 'open_sanslight';
  src: url('../../font/opensans-light-webfont.eot');
  src: url('../../font/opensans-light-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('../../font/opensans-light-webfont.woff') format('woff'),
    url('../../font/opensans-light-webfont.ttf') format('truetype'),
    url('../../font/opensans-light-webfont.svg#open_sanslight') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'open_sansregular';
  src: url('../../font/opensans-regular-webfont.eot');
  src: url('../../font/opensans-regular-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('../../font/opensans-regular-webfont.woff') format('woff'),
    url('../../font/opensans-regular-webfont.ttf') format('truetype'),
    url('../../font/opensans-regular-webfont.svg#open_sansregular')
      format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'open_sanssemibold';
  src: url('../../font/opensans-semibold-webfont.eot');
  src: url('../../font/opensans-semibold-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('../../font/opensans-semibold-webfont.woff') format('woff'),
    url('../../font/opensans-semibold-webfont.ttf') format('truetype'),
    url('../../font/opensans-semibold-webfont.svg#open_sanssemibold')
      format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'open_sansbold';
  src: url('../../font/opensans-bold-webfont.eot');
  src: url('../../font/opensans-bold-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('../../font/opensans-bold-webfont.woff') format('woff'),
    url('../../font/opensans-bold-webfont.ttf') format('truetype'),
    url('../../font/opensans-bold-webfont.svg#open_sansbold') format('svg');
  font-weight: normal;
  font-style: normal;
}
/*============== GRILLA ==============*/
.grid {
  margin: 0;
  min-height: 10px;
  margin-bottom: 20px;
  /*overflow: hidden;*/

  width: 100%;
  display: inline-block;
}
[class*='col-'] {
  position: relative;
  float: left;
  min-height: 1px;
}

.col-1 {
  width: 65%;
}
.col-2 {
  width: 15%;
}
.col-3 {
  width: 23.5%;
}
.col-4 {
  width: 32%;
}
.col-5 {
  width: 40.5%;
}
.col-6 {
  width: 49%;
}
.col-7 {
  width: 57.5%;
}
.col-8 {
  width: 66%;
}
.col-9 {
  width: 74.5%;
}
.col-10 {
  width: 83.5%;
}
.col-11 {
  width: 91.5%;
}
.col-12 {
  width: 100%;
}
/*============== TÍTULOS ==============*/
h1 {
  font-family: open_sanssemibold;
  line-height: 110%;
}
h2 {
  font-family: open_sanssemibold;
  line-height: 1.2em;
}
h3 {
  font-family: open_sanslight;
  line-height: 27px;
}
h4,
h5 {
  font-family: open_sansregular;
  line-height: 25px;
}
h6 {
  font-family: open_sanssemibold;
  line-height: 20px;
}
h1 {
  font-size: 2.615em;
}
/* 34px */
h2 {
  font-size: 2.615em;
}
/* 34px */
h3 {
  font-size: 1.692em;
}
/* 22px */
h4 {
  font-size: 1.385em;
}
/* 18px */
h5 {
  font-size: 1.231em;
}
/* 16px */
h6 {
  font-size: 1.077em;
}
/* 14px */
/* h1 span {
  font-family: open_sanslight;
} */
.title-line {
  text-align: center;
  /*background: url(../../images/Pescadores/border1.jpg) 0 25px repeat-x;*/
  margin-bottom: 30px;
}
.title-line h2 {
  background: #fff;
  padding: 10px;
}
.title-line h2,
.title-line h3 {
  display: table;
  margin: 0 auto;
  font-size: 2.308em;
  /* 34px */
}
.title-line.icon {
  background-position: 0 75px;
}
.title-line.icon [class^='icon-'],
.title-line.icon [class*=' icon-'] {
  display: block;
  margin: 0 auto;
}
.title-line.icon h2 {
  font-family: open_sanslight;
}
/*============== TEXTOS ==============*/
.bajada-titulo {
  font: 13px/14 open_sansregular;
}
.textos-legales {
  font: 9px/12 open_sansregular;
}
.center-txt {
  text-align: center;
}
/*============== GENERAL ==============*/
#general {
  background-color: #fff;
  /*min-height: 100%;*/

  height: auto !important;
  height: 100%;
  left: 0;
  position: relative;
  z-index: 1001;
  /*min-width:960px;*/
}
/*============== CONTENT ==============*/
#contenedor,
.content {
  width: 960px;
  height: auto;
  margin: 0 auto;
  clear: both;
}
/*#contenedor {
	padding-bottom: 150px;
}*/
.inner-content {
  width: 940px;
  margin: 0 auto;
  position: relative;
}
.inner-content-logo {
  width: 940px;
  margin: 0 auto;
  position: relative;
  text-align: center;
  /*border-bottom: 1px solid #aaaaaa;*/
}
.inner-content-logo a img {
  /*width: 186px;*/
  width: 160px;
  margin: 28px 0px 18px 0px;
}
.inner-content-form {
  width: 940px;
  margin: 0 auto;
  position: relative;
}
/*============== ARTICLE IMG ==============*/
.article-img {
  text-align: center;
  overflow: hidden;
}
.article-img.hazte-cliente {
  border: 1px #ccc solid;
  padding: 1rem;
}
.article-img.txt-left {
  text-align: left;
}
.article-img.txt-left p {
  font-size: 13px;
}
.article-img .ver-mas {
  margin-top: 20px;
}
.article-img h3,
.article-img h4,
.article-img h6 {
  margin: 20px 0 10px 0;
}
.article-img h6 {
  color: #b91a28;
  margin-top: 0;
}
.article-img a {
  display: block;
}
.article-img .accion {
  margin-top: 20px;
}
/*============== ELEMENTOS FORMULARIO ==============*/
input[type='text'],
input[type='password'],
input[type='tel'] {
  background: url('../../images/Pescadores/bg-input.jpg') repeat-x left top;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
  height: 33px;
  line-height: 33px;
  padding: 4px 10px;
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
}
input[type='text']:hover,
input[type='password']:hover,
input[type='tel']:hover,
input[type='text']:focus,
input[type='password']:focus,
input[type='tel']:focus,
textarea,
select {
  border-color: #ff6702;
}
button {
  border: 0;
  display: inline-block;
}

.form-group textarea {
  background: url('../../images/Pescadores/bg-input.jpg') repeat-x left top;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
  height: 200px;
  max-height: 200px;
  width: 57%;
  /*max-width: 57%;*/
  line-height: 20px;
  padding: 4px 10px;
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  resize: none;
}
.text-area input[type='text'] {
  height: 200px !important;
  display: block;
  line-height: 33px;
  padding: 4px 10px;
}
.captcha-input {
  margin: 2% 0 0% 32% !important;
  margin-top: 3%;
}
.captcha-label {
  height: 30px;
}
.seccion-label {
  color: #000;
  margin-bottom: 5%;
  border-bottom: 1px solid #aaa;
  width: 89%;
  /*font-weight: bold;*/
}
.checkbox-label {
  margin-bottom: 2%;
}
/*============== BOTONES ==============*/
.accion {
  font-size: 14px;
  background: #ccc;
  color: #fff;
  display: inline-block;
  height: 42px;
  line-height: 42px;
  min-width: 115px;
  padding: 0 10px;
  text-align: center;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  position: relative;
  z-index: 1;
}
.accion.btn-block {
  display: block;
}
.accion.transaccional {
  background: #b91a28;
}
.accion.transaccional:hover {
  background: #a90f1c;
}
.accion.ejecucion {
  background: #fa9600;
  border: 0px;
  margin-left: 10px;
  padding: 0 50px;
  min-width: 135px;
  -webkit-appearance: none;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
}
.accion.ejecucion:hover {
  background: #e38c0b;
  color: #fff !important;
}
.accion.ejecucion:active {
  background: #ffaa2b;
  box-shadow: inset 0px 0px 3px rgba(0, 0, 0, 0.4);
  transition: all 0;
  -moz-transition: all 0;
  -webkit-transition: all 0;
}
.accion.enfatizado {
  background: #777777;
}
.accion.enfatizado:hover {
  background: #5e5b5b;
}
.accion.no-enfatizado {
  background: #aaaaaa;
}
.accion.no-enfatizado:hover {
  background: #948f8f;
}
.accion.accion-img {
  padding: 0;
  /*background: url(../../images/Pescadores/bg-black-cover.png) repeat left top;*/
  border: 1px solid #fff;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.accion.accion-img span {
  float: left;
  padding: 0 10px;
}
.accion.accion-img i {
  /*background: url(../../images/Pescadores/icon-accion.jpg) 50% 50% no-repeat #ffffff;*/
  float: right;
  width: 30px;
  height: 42px;
}
.accion.seleccion {
  background: transparent;
  border: 2px #f49600 solid;
  color: #333333;
  font-size: 12px;
  float: left;
}
a.accion.seleccion:hover {
  border: 2px solid #b91a28;
}
.accion.ejecucion.btn-centrado {
  float: none;
  /*margin: auto 45%;*/
}
.btn-derecha {
  float: right;
  margin-right: 35px;
}
#landing-generica {
  padding-top: 40px;
}
#landing-generica img {
  float: left;
  margin: 0 15px 15px 0;
}
#landing-generica img.full-width-img {
  display: block;
  float: none;
  margin: 0 0 15px 0;
  width: 100%;
}
#landing-generica p {
  margin: 0 0 15px;
}
#landing-generica p span {
  color: #b91a28;
  font-weight: bold;
}
#landing-generica .block-text {
  padding: 20px 30px;
}
#landing-generica .block-text p {
  color: #333333;
  font-size: 16px;
  line-height: 22px;
}
.content-variable-landing .var-content .simulador .accion.seleccion:last-child {
  margin-right: 0;
}
.accion.seleccion:hover {
  border-color: #e38c0b;
}
.ver-mas {
  /*background: url(../../images/Pescadores/accion-ver-mas.png) top left no-repeat;*/
  width: 30px;
  height: 22px;
  border-bottom: 4px solid #eee;
  float: right;
  clear: both;
}
.leer-mas {
  /*background: url("../../images/Pescadores/bg-btn-leer-mas.png") no-repeat right top #fa9600;*/
  bottom: 0;
  color: #aaaaaa;
  font-size: 1.2em;
  height: 46px;
  left: 0;
  line-height: 46px;
  position: absolute;
  right: 0;
  text-indent: 20px;
}
.leer-mas:hover {
  color: #333;
}
.ico-link {
  color: #b91a28;
  text-decoration: underline;
}
.article-img:hover .ver-mas,
.ver-mas:hover {
  background-position: top right;
}

/*============== TABLAS ==============*/
.table {
  width: 100%;
}
.table th {
  font-family: open_sanssemibold;
}
.table > caption + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > th,
.table > thead:first-child > tr:first-child > th,
.table > caption + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > td,
.table > thead:first-child > tr:first-child > td {
  border-top: 0 none;
}
.table > thead > tr > th {
  border-bottom: 2px solid #dddddd;
  vertical-align: bottom;
}
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  /*border-top: 1px solid #DDDDDD;*/

  line-height: 1.42857;
  padding: 8px;
  vertical-align: top;
}
.table-consulta > caption + thead > tr:first-child > th,
.table-consulta > colgroup + thead > tr:first-child > th,
.table-consulta > thead:first-child > tr:first-child > th,
.table-consulta > caption + thead > tr:first-child > td,
.table-consulta > colgroup + thead > tr:first-child > td,
.table-consulta > thead:first-child > tr:first-child > td {
  border-top: 0 none;
}
.table-consulta > thead > tr > th {
  border-bottom: 2px solid #dddddd;
  vertical-align: bottom;
}
.table-consulta > thead > tr > th,
.table-consulta > tbody > tr > th,
.table-consulta > tfoot > tr > th,
.table-consulta > thead > tr > td,
.table-consulta > tbody > tr > td,
.table-consulta > tfoot > tr > td {
  /*border-top: 1px solid #DDDDDD;*/

  line-height: 1.42857;
  padding: 8px;
  vertical-align: top;
  width: 33%;
}
.table-consulta > tbody > tr:nth-child(2n + 1) > td,
.table-consulta > tbody > tr:nth-child(2n + 1) > th {
  background-color: #eee;
}
.table-striped > tbody > tr:nth-child(2n + 1) > td,
.table-striped > tbody > tr:nth-child(2n + 1) > th {
  background-color: #f9f9f9;
}
.table-bordered {
  border: 1px solid #dddddd;
}
.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td {
  border-bottom-width: 2px;
}
.table-bordered > thead > tr > th,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > td {
  border: 1px solid #dddddd;
}
.table-hover > tbody > tr:hover > td,
.table-hover > tbody > tr:hover > th {
  background-color: #f5f5f5;
}
.table > thead > tr > td.active,
.table > tbody > tr > td.active,
.table > tfoot > tr > td.active,
.table > thead > tr > th.active,
.table > tbody > tr > th.active,
.table > tfoot > tr > th.active,
.table > thead > tr.active > td,
.table > tbody > tr.active > td,
.table > tfoot > tr.active > td,
.table > thead > tr.active > th,
.table > tbody > tr.active > th,
.table > tfoot > tr.active > th {
  background-color: #d9edf7;
}
.table > thead > tr > td.success,
.table > tbody > tr > td.success,
.table > tfoot > tr > td.success,
.table > thead > tr > th.success,
.table > tbody > tr > th.success,
.table > tfoot > tr > th.success,
.table > thead > tr.success > td,
.table > tbody > tr.success > td,
.table > tfoot > tr.success > td,
.table > thead > tr.success > th,
.table > tbody > tr.success > th,
.table > tfoot > tr.success > th {
  background-color: #5d3261;
  color: #fff;
}
.table > thead > tr > td.info,
.table > tbody > tr > td.info,
.table > tfoot > tr > td.info,
.table > thead > tr > th.info,
.table > tbody > tr > th.info,
.table > tfoot > tr > th.info,
.table > thead > tr.info > td,
.table > tbody > tr.info > td,
.table > tfoot > tr.info > td,
.table > thead > tr.info > th,
.table > tbody > tr.info > th,
.table > tfoot > tr.info > th {
  background-color: #999;
  color: #fff;
}
.table > thead > tr > td.warning,
.table > tbody > tr > td.warning,
.table > tfoot > tr > td.warning,
.table > thead > tr > th.warning,
.table > tbody > tr > th.warning,
.table > tfoot > tr > th.warning,
.table > thead > tr.warning > td,
.table > tbody > tr.warning > td,
.table > tfoot > tr.warning > td,
.table > thead > tr.warning > th,
.table > tbody > tr.warning > th,
.table > tfoot > tr.warning > th {
  background-color: #666;
  color: #fff;
}
.table > thead > tr > td.danger,
.table > tbody > tr > td.danger,
.table > tfoot > tr > td.danger,
.table > thead > tr > th.danger,
.table > tbody > tr > th.danger,
.table > tfoot > tr > th.danger,
.table > thead > tr.danger > td,
.table > tbody > tr.danger > td,
.table > tfoot > tr.danger > td,
.table > thead > tr.danger > th,
.table > tbody > tr.danger > th,
.table > tfoot > tr.danger > th {
  background-color: #f2dede;
}
.center h3 {
  text-align: center;
}
.table > tbody > tr.desplegable_tr + tr {
  background-color: #eee;
}
.table > tbody > tr.desplegable_tr td {
  background: #fff !important;
}
/*============== FOOTER ==============*/
#footer {
  background: #f1f3f2;
  width: 100%;
  /*margin-top: 30px;*/
  /*min-width: 960px;*/
  position: relative;
}

/*============== TABLE REFLOW ==============*/
.reflow {
  margin: 40px 0;
  display: table;
  width: 100%;
}

.reflow .inner-reflow {
  display: table-cell;
}

.reflow h4 {
  padding-left: 45%;
}

.reflow dl {
  display: table;
  width: 100%;
}

.reflow dt {
  font-weight: bold;
  color: red;
  display: table-cell;
}

.reflow dd {
  display: table-cell;
  margin-left: 2%;
  margin-bottom: 20px;
}
/*::::::::::::PAGINA CONTACTO :::::::::::::*/
.title-line h3.especial {
  font-size: 1.8em;
}

.icon-contactanos-medium {
  /*background: url("../../images/Pescadores/icon-contactanos-large.png") no-repeat center -15px;*/
  height: 44px;
  width: 40px;
}
#contacto {
  padding: 50px 0 90px;
}
#changesize {
  float: right;
}
.zoomin {
  /*background: url(../../images/Pescadores/zoomtext.png) no-repeat left top;*/
  width: 30px;
  height: 20px;
  text-indent: -99999px;
  float: left;
}
.zoomin:hover {
  background-position: left bottom;
}
.zoomout:hover {
  background-position: right bottom;
}
.reset {
  display: none;
}
.zoomout {
  /*background: url(../../images/Pescadores/zoomtext.png) no-repeat right top;*/
  width: 30px;
  height: 20px;
  text-indent: -99999px;
  float: left;
}
#avisobrowser {
  display: none;
}
.share {
  width: 190px;
  float: right;
}
.desplegable {
}

.ayuda-pers {
  background-color: #f6f6f6;
  position: relative;
  float: right;
  z-index: 9999;
  font-size: 12px;
  box-sizing: border-box;
  padding: 10px 15px;
  cursor: default;
  margin: 10px 0 0;
  width: 68%;
}
.desplegable-select {
  clear: both;
}
tr.desplegable_tr td .ayuda-pers {
  width: 100%;
  margin-top: 0;
  float: left;
}
.desplegable-corner .ayuda-pers {
  width: 98%;
  padding: 10px 10px;
  margin-top: 10px;
  margin-bottom: 0px;
  font-size: 11px;
  background: #eee;
}
/* HAZTE CLIENTE */
.ayuda-pers span {
  display: block;
  margin-bottom: 5px;
}
.selector-productos {
  margin-bottom: 20px;
}

.selector-productos .radio,
.selector-productos .checkbox {
  margin-bottom: 15px !important;
}

.selector-productos .radio span,
.selector-productos .checkbox span {
  display: block;
  margin: 5px 0;
  font-size: 0.75em;
}
.selector-productos a {
  line-height: 130%;
}
.selector-productos a:hover {
  color: #b91a28;
  text-decoration: underline;
}

.selector-productos ul ul {
  margin: 10px 0;
}
/* COMPARADOR */
.comparador-tc {
  display: table;
}
.comparador-tc .accion {
  margin: 0 auto;
  width: 130px;
  display: block;
}
.comparador-tc .item-comp {
  border: 1px solid #b8b8b8;
  float: left;
  height: 40px;
  margin-right: 0.5rem;
  position: relative;
  width: 50px;
}
.item-comparado {
  border-bottom: 1px dashed #ccc;
  float: left;
  height: auto;
  padding-bottom: 10px;
  margin-bottom: 10px;
  width: 100%;
}
.item-comparado p {
  float: left;
  height: 100%;
  line-height: 120%;
  margin: 0;
  padding: 5px 0;
  width: 65%;
}
.comparador-tc .item-comp .delete-tc-comp {
  background: url('../../images/Pescadores/btn-eliminar-tc-comp.jpg') no-repeat
    right top;
  bottom: 0;
  /*display:none;*/
  height: 17px;
  position: absolute;
  right: 0;
  text-indent: -9999px;
  width: 17px;
}
.content-comparador {
  margin-top: 2rem;
}
.content-comparador .comparador-tc {
  margin-top: 0.5rem;
}
.content-comparador > p {
  margin: 0;
}
.col-3 #mod-comparador {
  width: 180px;
  padding: 20px;
}
.deshabilitado {
  background: #ebebeb;
  pointer-events: none;
  cursor: default;
}
.link-text-desabilitado {
  color: #ccc !important;
  text-decoration: none !important;
  cursor: default;
  pointer-events: none;
}
.fallback {
  display: block;
  width: 100%;
  height: 20px;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 100;
  background: transparent;
}
.error-message {
  color: #b91a28;
  display: none;
  text-align: center;
}
.relative {
  position: relative;
}
.fixed {
  position: fixed;
  top: 0;
  z-index: 100;
}

/* inicio pagina de login */

.laterallogin {
  margin-bottom: 20px;
  border-bottom: #ccc 1px solid;
  padding-bottom: 15px;
  height: 95px;
}

.imglateral {
  float: left;
}

/* fin pagina de login */

/* COMPARADOR */
@media (max-width: 760px) {
  .reflow .inner-reflow {
    display: block;
    margin-bottom: 20px;
    overflow: hidden;
  }

  .reflow h4 {
    padding-left: 0;
    margin-bottom: 20px;
  }

  .reflow dl {
    margin-bottom: 20px;
  }

  .reflow dt {
    padding-right: 20px;
  }
}

/*  CLASES EXTRAS - Estas clases tienen sus respectivos atraibutos en la hoja de estilos responsive.css para
una correcta visualización en los distintos contextos responsivos. */

.aux-input {
  margin-top: 4%;
}

.radiobox {
  padding: 3%;
}

.last_radio {
  margin-left: 10%;
}

/*  Formulario Preaprobación - Sidebar  */
/*
#consulta {
  padding: 30px 0 10px;
  position: relative;
  margin-bottom: 30px;
}

.form-consulta {
  display: table;
  margin-bottom: 20px;
  width: 100%;
  vertical-align: middle;
}
.form-consulta label {
  float: left;
  width: 30%;
  margin-right: 2%;
  margin-top: 1.2em;
  margin-bottom: 5px;
  position: relative;
}
.form-consulta label + label {
  margin-right: 5%;
  width: auto;
}

.form-consulta .input-consulta input[type="text"],
.form-consulta .input-consulta input[type="password"],
.form-consulta .input-consulta input[type="tel"] {
  display: inline;
  width: 92%;
  margin-right: 0%;
}
*/

.btn-consulta {
  background-color: #777777;
  border: none;
  margin: 0%;
  width: 25%;
}

.btn-consulta:hover {
  background-color: #5e5b5b;
}
/*   Caja Vecina   */

#logo-2 {
  float: right;
  margin-top: 28px;
  width: 186px;
}

#img_captcha_container {
  width: 240px;
  height: 80px;
  display: block;
  float: left;
  margin-bottom: 5px;
  background: url('../../images/Pescadores/loading-orange.gif') center center
  
    no-repeat;
}

/*  SEGURO PESCADORES */

.llamado h3 span {
  font-family: open_sansregular;
  font-weight: bold;
}

#medio-pago {
  margin-top: 30px;
}
#select-medio-pago {
  border: 1px solid #ccc;
  padding: 20px;
  width: fit-content;
  text-align: center;
  position: relative;
  left: 50%;
  transform: translate(-50%,0);
}
#select-medio-pago input[type='radio'] {
  display: grid;
  width: 30px;
  line-height: 50px;
}
#select-medio-pago img {
  max-width: 100px;
  height: auto;
  vertical-align: middle;
  margin-left: 20px;
  margin-right: 20px;
}
.bt {
  border-top: 1px solid #ccc;
  margin-top: 30px;
  margin-bottom: 30px;
}
#total {
  margin-top: 30px;
  margin-bottom: 30px;
}
#total h4,
#medio-pago .m-bottom h3,
#total h2,
#total input,
#total label,
#total p {
  float: none;
  text-align: center;
}
#condiciones {
  margin: 10px auto;
  text-align: center;
}
#codigo {
  text-align: center;
  margin: 30px 25%;
}
#codigo .form-general {
  display: table;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  vertical-align: middle;
}
#codigo .form-general .has-feedback {
  width: 100%;
}
#codigo .form-general input[type='text'],
#codigo .form-general input[type='password'],
#codigo .form-general input[type='tel'] {
  display: inline;
  width: 57%;
}
/*#condiciones input, label {
  float: none;
  text-align: center;
  margin: auto;
}*/
#telefonos {
  text-align: center;
  font-size: 1.5em;
  line-height: 30px;
  margin-top: 30px;
}
#telefonos img,
#telefonos h3 {
  float: none;
  text-align: center;
  display: inline-block;
}
#telefonos img {
  width: 13px;
  height: 13px;
}

#telefonos a {
  color: #fa9600;
  font-weight: bold;
  font-size: 13px;
  margin-top: 5px;
}

.inner-content-logo .back-btn {
  width: 24px;
  height: 17px;
  background: url(../../images/Pescadores/icon-arrow-prev.png) 0px 0px no-repeat;
  display: block;
  background-size: auto 17px;
  top: 33px;
  left: 20px;
  position: absolute;
  z-index: 99;
}
.verificacion {
  text-align: center;
  margin-left: 25%;
}

/*  Pasos Mobile  */

.contenedor_general {
  width: 100%;
  overflow: hidden;
  background-color: #fff;
  padding: 15px 0px;
  margin-bottom: 5px;
}
.contenedor {
  width: 80%;
  height: 15px;
  border-bottom: 2px solid #aaa;
  margin: 0 auto;
}
.pasosinicio {
  width: 10%;
  float: left;
  height: auto;
  text-align: left;
}
.pasosinter {
  width: 10%;
  float: left;
  height: auto;
  text-align: center;
}
.pasosfinal {
  width: 10%;
  float: right;
  height: auto;
  text-align: right;
}
.pasosinicio img {
  width: 32px;
}
.pasosinter img {
  width: 32px;
}
.pasosfinal img {
  width: 32px;
}
.linea_naranja {
  width: 35%;
  float: left;
  height: auto;
  height: 15px;
}

#modal_resumen_producto {
  display: none;
}

.variation label.error {
  margin-left: 0;
}

#condiciones label.error {
  margin-left: 0px;
}

#formInstancePaso3.errors {
  text-align: center;
  color: red;
}

.botonvolver {
  color: #fa9600;
}

label.error {
  width: 70%;
  margin-left: 33%;
  color: #ff0000;
}

.accion.contratacion {
  background: #fa9600;
  border: 0px;
  margin-left: 10px;
  padding: 0;
  width: auto;
  -webkit-appearance: none;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
}
.accion.contratacion:hover {
  background: #e38c0b;
  color: #fff !important;
}
/*codigo provisorio deshabilitar hover botons de contratación*/
.no-hover {
  background: #ccc;
  cursor: not-allowed;
  border: 0px;
  margin-left: 10px;
  padding: 0;
  width: auto;
  -webkit-appearance: none;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  font-size: 14px;
  background: #ccc;
  color: #fff;
  display: inline-block;
  height: 42px;
  line-height: 42px;
  min-width: 115px;
  padding: 0 10px;
  text-align: center;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  position: relative;
  z-index: 1;
}

.myModal {
  border-radius: 20px;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 99999;
  transform: translate(-50%, -50%);
  width: 30%;
  padding: 40px;
}

.myUl {
  padding-left: 30px;
}

.myUl, .myUl li {
  list-style-type: disc!important;
}

.myUl li {
  margin-bottom: 10px!important;
}

.myUl.declaracion {
  list-style-type: circle;
}

table.coverage {
  font-family: system-ui;
}

table.coverage td > div > span:first-child, table.coverage tfoot td > span:first-child {
  font-weight: bold;
  font-size: 15px;
}
