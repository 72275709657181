/* CSS crunched with Crunch - http://crunchapp.net/ */
/*COLORS*/
/*TIPOGRAFIAS*/
/**/
/* 960px */
@media (min-width: 760px) and (max-width: 1920px){
  .error-txt {
  color: #b91a28;
  float: left;
  margin-left: 33%;
  margin-top: 0.5em;
  }
  .restante_caracteres {
  float: left;
  margin-left: 33%;
  margin-top: 0.5em;
  }
  input.error-txt {
  margin-left:0%;
}
.inner-content-logo .back-btn {
  display: none;
}
#menuMobile {
  display: none;
}
#pasosDesktop {
  display: block;
}
}
@media (min-width: 760px) and (max-width: 980px){
 /** COMPARADOR **/ 
  .comparador-tc{
    padding: 2%;
      width: 96%;
  }
  #logo {
  float: none;
  display: block;
  width: 186px;
  margin: 0 auto;
  overflow: hidden;
}
#productComparison th .accion, #fixed-table-header th .accion {
    min-width: 40%;
    width: 40%;
}
  .col-3 #mod-comparador {
    width: 126px;
  }
  .comparador-tc .item-comp{
    float: none;
    display: block;
    margin: 0 auto;
  }
  .item-comparado p{
    line-height: 120%;
    margin-top: 10px;
    width: 100%;
    text-align: center;
  }
  #mod-comparador .accion{
    padding: 0; 
  }
  /** COMPARADOR **/
.tooltip-pers {
    background-color: #f6f6f6;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 0 5px #ccc;
    cursor: default;
    display: none;
    font-size: 12px;
    left: -256px;
    padding: 10px;
    position: absolute;
    right: 28px;
    top: -20px;
    z-index: 9999;
}
#banner .flexslider .carousel-caption.center {
    margin: 0 auto;
    padding: 6% 0;
    position: static;
    width: 70%;
}
#banner .flexslider .item, #banner .slides .item {
    height: 300px;
}
#banner .flexslider.esp .item > img{
    max-width: 150%;
    width: 150%;
}
#banner .flexslider.site .item > img {
    max-width: 130%;
    width: 130%;
}
#banner .flexslider.prod-dest .item > img, #banner .owl-carousel.prod-dest .item > a > img{
  max-width: 100%;
    width: 100%;
}
#banner .site .slides .item {
    height: 200px;
}
#banner .site .slides .item .carousel-caption {
    width:70%;
}
#nav li:nth-child(2) .sub-menu { left: -200px!important; }
#nav li:nth-child(3) .sub-menu,
#nav li:nth-child(4) .sub-menu { left: -250px!important; }

#login #log-section h2 { font-size: 2em; }

#banner .carousel.hcliente .item .carousel-caption.center {
  padding: 4% 0 0 0;
}
#banner .flexslider .item .carousel-caption {
  bottom: auto;
    width: 60%;
}
#header .nav-top li{
    height:35px;
    width:93px;
}
#header .nav-top li.longer{
    height:35px;
    width:85px;
}
#header .nav-top a{
    line-height:120%;
}
  #contenedor {
    width: 98%;
  }
  .content,
  .inner-content,
  .inner-content-logo {
    width: 96%;
    padding: 0 2%;
  }
  .inner-content-logo {
    width: 96%;
    padding: 0 2%;
  }
  #destacados-home .inner-content,
  #destacados-home .inner-content-logo {
    width: 96%;
  }
  #productos2,
  #wrap-productos,
  #header .inner-content,
  #header .inner-content-logo {
    width: 98%;
    padding-left: 1%;
    padding-right: 1%;
  }
  .bg-head {
    display: none;
  }
  .content-nav {
    width: 74%;
  }
  #nav {
    width: 100%;
  }
  #nav > .principal > li > a {
    font-size: 1em;
    padding: 0 9px;
  }
  #banner,
  .carousel-inner > .item > img,
  .carousel-inner > .item > a > img {
    min-width: 100%;
    max-height: 350px;
  }
   #banner ol.flex-control-nav {
    bottom: 18px;
    }
  .carousel .item {
    height: 265px;
  }
#banner .esp .slides .item {
  height: 180px;
}
  #site-side .article-img {
    margin: 0;
  }
  #faq #head-faq .buscador-faq {
    width: 30%;
  }
  #faq #head-faq .buscador-faq .search {
    width: 100%;
  }
  #banner .carousel-caption {
    display: block;
    right: 0;
    width: 60%;
    top: 20%;
    /*img {
            display: none;
        }*/
  }
.art-camp .cont-art a p{
  line-height:150%!important;
}
.art-camp .cont-art a {
  padding: 10px;
}
  #footer .content {
    width: 98%;
    padding: 0;
  }
  #footer .content .right-foo .fono-info {
    display: inline-block;
    float: none;
    font-size: 1em;
  }
  #footer .content .right-foo .sociales-foo span {
    font-size: 0.9em;
  }
  #destacados-home {
    min-width: 100%;
  }
  #destacados-home .section-cam {
    width: 55%;
  }
  .sect-noticias {
    width: 37%;
  }
  .art-camp {
    width: 30.4%;
  }
  #banner .carousel .item.landing > img {
    min-width: 1160px;
  }
  .content-variable-landing .var-content {
    height: auto;
  }
  .content-variable-landing .var-content .simulador li {
    text-indent: -9999px;
  }
  .content-variable-landing .var-content .simulador li.active {
    text-indent: 0;
  }
  .content-variable-landing .var-content .simulador li span {
    text-indent: 0;
  }
  .content-variable-landing .var-content .simulador .accion.seleccion {
    line-height: normal;
    padding-top: 4px;
    width: 20%;
  }
  .content-variable-landing .var-content .sect-noticias {
    margin-left: 5%;
    width: 50%;
  }
  #comparador-tc{
  padding: 3%;
    width: 94%;
  }
    #footer{
    height:250px;
    }
    #footer .left-foo {
    height: 206px;
  }
  .error-txt {
  color: #b91a28;
  float: left;
  margin-left: 33%;
  margin-top: 0.5em;
  }
  .restante_caracteres {  
  float: left;
  margin-left: 33%;
  margin-top: 0.5em;
  }
  input.error-txt {
  margin-left:0%;
  }
  .form-group textarea {
    width: 57%;
    max-width: 57%;
    padding: 4px 10px;
    height: 150px;
  }

  /* CONSULTA ESTADO SOLICITUD */
  #consulta #log-section #content-login .tab-content .form-general label {
    margin-top: 0;
    margin-bottom: 0.3em;
    width: 100%;
  }
  #consulta #log-section #content-login .tab-content .form-general {
  float: left;
  width: 100%;
  }
  #consulta #log-section #content-login .tab-content input[type="text"],
  #consulta #log-section #content-login .tab-content input[type="password"] {
    width: 98%;
    margin-right: 0;
  }
  #consulta #log-section #content-login .tab-content .accion {
    display: block;
    min-width: 100%;
    padding: 0 5%;
  }
  .inner-content-logo .back-btn {
  display: block;
  }
  #pasosDesktop {
  display: block;
}
}
@media (max-width: 768px) {
  .myModal{
    width: 80%;
  }
}
@media (max-width: 760px) {
  .llamado {
    margin: 2em 0 0;
}
.accion.ejecucion.btn-centrado {
  float: none;
  margin: auto;
}
.col-3 #mod-comparador{
      padding: 20px 4%;
      position: relative !important;
      width: 92%;
  }
#tarifas .categorias-serv > ul li.titleCategory {
    line-height:130%;
}
.productComparison{
    width: 100%;
}
  .bg-miga { display: none; }
  body { font-size: 14px; }
  #banner .slides .item {
  height: 150px;
  }
  #banner .slides .item .carousel-caption{
  bottom: 35%;
  top: 13%;
  width: 72%;
  }
#banner-noticias .carousel img{
    display:none;
 }
.flex-direction-nav {
    left: 40%;
}
.tooltip-pers {
    left: 10%;
    padding: 10px;
    position: fixed;
    right: 10%;
    top: 25%;
    z-index: 9999;
}
.tooltip-pers span.bullet {
    /*background: url("../../images/Pescadores/bullet-tooltip-bottom.png") no-repeat scroll right top rgba(0, 0, 0, 0);*/
    height: 16px;
    right: 0;
    left:48%;
    position: absolute;
    bottom: 20px;
    top:inherit;
    width: 13px;
}
  .owl-theme .owl-controls, #banner ol.flex-control-nav {
    padding-top:0.5rem;
        padding-bottom:0.5rem;
    position: static;
        clear:both;
  }
  #banner .slides .item > img,
  #banner .slides .item > img,
  #banner .slides .item > a > img {
    left: -60px;
    min-width: 121%;
  }
#faq #head-faq {
    display: inline-block;
    margin-bottom: 0em;
    padding-bottom: 0em;
}
#head-serv .categorias-serv > ul{
    float: none;
    clear:both;
    width: 100%;
}
    
  #footer .right-foo .legal {
    font-size: 17px!important;
    padding: 20px;
  }

   html #footer .right-foo > ul li a {
    font-size: 1em;
  }

  .article-img .ver-mas {
    display: none;
  }

  /*body{
        overflow-x:hidden;
    }*/

  #site-side,
    #site-side .grid {
    margin-bottom: 0;
  }

  .modulos-grales .leer-mas {
    background: none;
  }

  #footer .right-foo {
    padding-top: 0;
  }


  .tipo-productos .content-tbl .productHeadings th h6 {
    min-height: 80px!important;
  }

  .content-variable-landing .var-content .simulador ul {
    margin-bottom: 0;
  }  

 .content-variable-landing {
    margin-bottom: 40px;
  }  

  .desktop {
    display: none;
  }
  .smartphone {
    display: block;
  }
  .noscroll {
    overflow-x: hidden;
    /*position: fixed;*/
  }
  #contenedor {
    width: 96%;
    padding: 0 2%;
  }
  .productComparison tr .even,
  .productComparison tr.productLinks .odd {
    padding: 3%;
  }
  .content {
    width: 100%;
  }
  .inner-content,
  .inner-content-logo {
    width: 96%;
    padding: 0 2%;
  }
  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12 {
    width: 100%;
    float: none;
  }
  [class*="col-"] + [class*="col-"] {
    margin: 0em 0 0 0;
  }
  #nav-top-select {
    display: block;
    width: 90%;
    padding: 5% 5% 0;
  }
  #nav-top-select select {
    width: 100%;
  }
  .nav-top {
    display: none;
  }
  #head-ficha .accion {
    display: block;
    margin: 0.5em 0 0;
  }
  .img-campana {
    display: none;
  }
  #miga {
    height: auto;
    border-bottom: none;
  }
  #miga .content {
    width: 95%;
  }
  #miga .content ul {
    background-color: #fff;
    padding-top: 0.5em;
  }
  #miga .content ul li {
    min-height: 25px;
  }
  #miga .content ul li a {
    /*background: url("../../images/Pescadores/separator-miga.png") no-repeat scroll right center rgba(0, 0, 0, 0);*/
    margin-right: 5px;
    padding: 3px 15px 3px 10px;
  }
  #miga .content ul li span {
    background: none;
    padding: 3px 15px 3px 10px;
  }
  #miga .content ul li:first-child a {
    padding: 0 1em 0 0;
  }
  #miga .content ul li:last-child span {
    background: none;
    margin-left: 0;
    padding: 3px 10px;
  }
  .tipo-productos .content-tbl .productHeadings th {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
  .sub-menu .menu h3 {
    display: none;
  }
  .sub-menu .section-menu ul {
    padding-bottom: 0;
  }
  .sub-menu .section-menu ul li {
    border-bottom: 1px #ccc solid;
    padding: 10px 0;
    margin: 0;
  }
  .sub-menu .section-menu ul li:hover {
    background-color: #eaeaea;
  }
  .nav-tabs.vertical,
  .tab-content.vertical {
    float: none;
    width: 100%;
    margin: 0;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }
  .nav-tabs.vertical,
  .tab-content.vertical {
    width: 40%;
  }
  #solicitud-producto #tab-productos .tab-content {
    width: 60%;
  }
  .list-selector-mobile .seleccion-opcion {
    background: url("../../images/Pescadores/bg_select.png") no-repeat scroll right top #ffffff;
    border: 1px #ccc solid;
    display: block;
    height: 40px;
    line-height: 40px;
    margin: 1em 0 0;
    text-indent: 15px;
    width: 100%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }
  .list-selector-mobile .nav-tabs {
    background-color: #fff;
    height: 0;
    overflow: hidden;
    width: 100%;
    margin-bottom: 1em;
  }
  .list-selector-mobile .nav-tabs li.active a {
    background-color: #fa9600;
    color: #fff;
  }
  .list-selector-mobile .nav-tabs.collapse {
    display: none;
    height: 0 !important;
  }
  .tab-content.vertical {
    margin: 0 0 15px 0;
  }
  .tab-content.w-selector {
    width: 100%;
  }
  .tools {
    margin-bottom: 2em;
  }

  .noticia-sala-prensa .tools {
    margin-bottom: 0;
  }

  #banner .carousel .item.landing {
    height: auto;
    margin: 1.5em 0;
  }
  #banner .carousel .item.landing > img {
    display: none;
  }
  #banner .carousel .item.landing .container .carousel-caption {
    padding: 0 5%;
    position: static;
    text-align: left;
    width: 90%;
  }
  #banner .carousel .item.landing .container .carousel-caption h2 {
    color: #333;
    font-family: open_sanssemibold;
    font-size: 3.5em;
    line-height: 1.3em;
  }
  #mensaje {
    padding: 3em 1em;
  }
  .content-variable-landing {
    /*display:none;*/
  }
  .content-variable-landing .inner-content,
  .content-variable-landing .inner-content-logo{
    width: 100%;
    padding: 0;
  }
  .content-variable-landing .claim {
    background-color: #F39801;
    width: 90%;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 1em;
    padding-top: 1em;
  }
  .content-variable-landing .var-content {
    width: 100%;
    height: auto;
  }
  .content-variable-landing .var-content .simulador {
    width: 90%;
    padding-left: 5%;
    padding-right: 5%;
  }
  .content-variable-landing .var-content .simulador .accion.seleccion {
    display: block;
    float: none;
    font-size: 14px;
  height: auto;
  min-width:90%;
  max-width:90%;
  padding-left:5%;
  padding-right:5%;
  }
  .content-variable-landing .var-content .simulador .accion{
    margin-bottom: 0.5em;
  }
  .content-variable-landing .var-content .simulador .accion:last-child {
    float: none;
    margin-bottom: 0;
  }
  .content-variable-landing .var-content .simulador ul li {
    width: 100%;
    margin-bottom: 1em;
  }

    .content-variable-landing .var-content .simulador ul li span {
      margin-right: 0.5em;
    }

  .content-variable-landing .var-content .test {
    margin-top: 2em;
    width: 90%;
    padding-left: 5%;
    padding-right: 5%;
  }
  .content-variable-landing .var-content .sect-noticias {
    padding: 0 5%;
    width: 90%;
  }
  .article-ico {
    float: none;
    text-align: left;
    /*border-left: 4px solid @color-3;*/
    margin-bottom: 3em;
    min-height: 60px;
  }
  .article-ico [class^="icon-"],
  .article-ico [class*=" icon-"] {
    background-image: none;
    float: left;
    width: 4px;
    height: 60px;
    margin-right: 10px;
  }
  .article-ico .icon-proyectos,
  .article-ico .icon-m-financiamiento {
    background-color: #fa9600;
  }
  .article-ico .icon-finanzas,
  .article-ico .icon-m-invertir {
    background-color: #d75814;
  }
  .article-ico .icon-compras,
  .article-ico .icon-m-proteger {
    background-color: #b91a28;
  }
  .article-ico .icon-ahorro,
  .article-ico .icon-m-enviaDinero {
    background-color: #5d3261;
  }
  .article-ico .icon-rentabilidad,
  .article-ico .icon-m-ayudaProyectos,
  .article-ico .icon-i-controlDinero {
    background-color: #00419a;
  }
  .article-ico .icon-seguros,
  .article-ico .icon-m-finanzas {
    background-color: #222222;
  }
  [class*="col-"] + [class*="col-"]:last-child .article-ico {
    margin-bottom: 2em;
  }
  #nav .principal li .home {
    padding: 0 15px;
    width: auto;
    text-align: left;
  }
  #nav .principal li .home span {
    display: inline;
  }
  #nav .principal li .home .icon-home {
    display: none;
  }
  #nav .principal li a {
    padding-left: 1em;
  }
  #nav .principal li a.titleCategory {
    padding-left: 0;
  }
  .navbar-toggle {
    display: block;
  }
  .btn-login-smartphone {
    /*background: url("../../images/Pescadores/icon-login.png") no-repeat left top;*/
    display: block;
    position: absolute;
    right: 10px;
    top: 20px;
    width: 13px;
    height: 17px;
    text-indent: -9999px;
  }
  #logo {
    float: none;
    display: block;
    width: 186px;
    margin: 0 auto;
    overflow: hidden;
  }
  #logo-2 {
    float: none;
    display: block;
    width: 186px;
    margin: 0 auto;
    overflow: hidden;
  }
  #logo div {
    margin: 0px;
  }
  .bg-head {
    display: none;
  }
  #general {
    transition: 0.3s left;
    -moz-transition: 0.3s left;
    -webkit-transition: 0.3s left;
    -ms-transition: 0.3s left;
    -o-transition: 0.3s left;
  }
  #general.open-lateral {
    left: 90%;
    position: relative;
    /*min-width: 400px;*/
  }
  #destacados-home {
    display: none;
  }
  #header {
    position: static;
  }
  #header .inner-content,
  #header .inner-content-logo {
    width: 100%;
    padding: 0;
    position: static;
  }
  #header .content-nav {
    background: #b91a28;
    position: fixed;
    -webkit-backface-visibility: hidden;
    left: -90%;
    height: 100%;
    width: 90%;
    top: 0;
    overflow-y: auto;
    z-index: 1005;
    transition: left 0.3s;
    -moz-transition: left 0.3s;
    -webkit-transition: left 0.3s;
  }
  #header .content-nav.open-lateral {
    box-shadow: 0 0 15px #000;
    -moz-box-shadow: 0 0 15px #000;
    -webkit-box-shadow: 0 0 15px #000;  
      overflow-y:auto!important;
      left:0;
  }
  #header .content-nav .ayuda-head {
    min-width: 200px;
  }
  #header .content-nav #nav {
    width: 100%;
    min-width: 200px;
  }
  #header .content-nav #nav > .principal {
    float: none;
    width: 100%;
  }
  #header .content-nav #nav > .principal > li {
    border-bottom: 1px solid #861A28;
    float: none;
    width: 100%;
    margin: 0;
    height: auto;
  }
  #header .content-nav #nav > .principal > li + li {
    margin: 0;
  }
  #header .content-nav #nav > .principal > li .sub-menu {
    display: block;
    height: auto;
    overflow: hidden;
    padding: 4%;
    position: relative;
    width: 92%;
    display: none;
    top: 0;
  }
  #header .content-nav #nav > .principal > li .sub-menu.open {
    display: block;
  }
  #header .content-nav #nav > .principal > li .sub-menu .menu .section-menu {
    border: none;
    display: block;
    float: none;
    margin: 0;
    padding: 0;
    width: 100%;
  }
  #header .content-nav #nav #banca-linea {
    display: none;
  }
  #site-side .article-img {
    margin: 0;
  }
  #banner {
    min-width: 100%;
  }
  #banner .carousel-indicators li {
    width: 10px;
    height: 10px;
  }

  #banner .carousel.site .carousel-inner .item {
    height: auto;
  }
  #banner .carousel.site .carousel-inner .item > img {
    display: none;
  }
  #banner .carousel.site .carousel-inner .item .container .carousel-caption {
    width: 90%;
    padding: 5%;
    position: static;
  }
  #banner .carousel.site .carousel-inner .item .container .carousel-caption > img {
    display: block;
    margin: 0 auto;
  }
  #banner .carousel.site .carousel-inner .item .container .carousel-caption h2 {
    color: #333;
    font-size: 2em;
  }
  #banner .carousel.site .carousel-inner .item .container .carousel-caption .bajada p {
    color: #333;
  }
  #banner .carousel.prod-dest .carousel-inner .item {
    height: auto;
  }
  #banner .carousel.prod-dest .carousel-inner .item > img {
    display: none;
  }
  #banner .carousel.prod-dest .carousel-inner .item .container .head-modulo {
    width: 90%;
    padding: 5%;
    position: static;
  }
  #banner .carousel.prod-dest .carousel-inner .item .container .head-modulo h2 {
    color: #333;
  }
  #banner .carousel.prod-dest .carousel-inner .item .container .head-modulo .bajada p {
    color: #333;
  }
  #banner .carousel .item {
    height: 195px;
    overflow: hidden;
  }
  #banner .item .container {
    width: 100%;
  }
  #banner .carousel-caption {
      display: block;
      right: 2%;
      top: 20%;
      width: auto;
  }

  #banner .carousel-caption h2 {
      font-family: open_sanssemibold;
      font-size: 18px;
      line-height: 20px;
  }

  #banner .carousel-caption h3 {
      font-family: open_sanslight;
      font-size: 16px;
      line-height: 18px;
  }
#banner .flexslider.esp .item .carousel-caption h2 {
    color: #333;
    font-size: 2.8rem;
    line-height: normal;
    text-align: left;
}
 #banner .flexslider.esp .item .carousel-caption {
    position: static;
}
  #banner .owl-carousel.esp .carousel-caption > img {
    display: none;
  }
  #banner .carousel-caption .accion {
    font-size: 0.846em;
  }
  #wrap-productos {
    padding-top: 20px;
  }
  .search {
    width: 90%;
    float: none;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .content-nav {
    display: block;
    float: none;
  }
  .title-line h2,
  .title-line h3 {
    /*text-transform:uppercase;*/
    font-size: 1.9em;
    line-height: 29px;
  }
  #destacados-home {
    min-width: 100%;
  }
  #destacados-home .section-cam {
    display: none;
  }
  #destacados-home .sect-noticias {
    width: 100%;
  }
  #section-productos .grid {
    margin-bottom: 0;
  }

  ul.pasos li {
    width: 90%;
    margin-bottom: 1px;
  }
  ul.pasos li span.deco-pasos {
    display: none;
  }
  ul.pasos li.selected {
    background-color: #fa9600;
  }
  /*  PRUEBA BARRA DE PROGRESO  */
  #progressbar {
  margin-bottom: 30px;
  overflow: hidden;
  /*CSS counters to number the steps*/
  counter-reset: step;
}
#progressbar li {
  list-style-type: none;
  color: white;
  text-transform: uppercase;
  font-size: 9px;
  width: 25%;
  float: left;
  position: relative;
}
#progressbar li:before {
  content: counter(step);
  counter-increment: step;
  width: 20px;
  line-height: 20px;
  display: block;
  font-size: 10px;
  color: #333;
  background: white;
  border-radius: 3px;
  margin: 0 auto 5px auto;
}
/*progressbar connectors*/
#progressbar li:after {
  content: '';
  width: 100%;
  height: 2px;
  background: white;
  position: absolute;
  left: -50%;
  top: 9px;
  z-index: -1; /*put it behind the numbers*/
}
#progressbar li:first-child:after {
  /*connector not needed before the first step*/
  content: none; 
}
/*marking active/completed steps green*/
/*The number of the step and the connector before it = green*/
#progressbar li.active:before,  #progressbar li.active:after{
  background: #27AE60;
  color: white;
}
  #banner-noticias .carousel-inner > .item .carousel-caption {
    display: block;
  }
 
  #banner-noticias .carousel-control.left {
    left: 0;
    bottom: 0;
  }
  #banner-noticias .carousel-control.right {
    left: 40px;
    bottom: 0;
  }
  #banner-noticias .carousel {
    border-left: 1px #ccc solid;
    border-right: 1px #ccc solid;
    border-bottom: 1px #ccc solid;
  }
  #faq #head-faq .buscador-faq {
    width: 100%;
    margin-bottom: 1em;
  }
  #faq #head-faq .buscador-faq .search {
    width: 100%;
  }
  #login #log-section #content-login .tab-content .form-general label {
    margin-top: 0;
    margin-bottom: 0.3em;
    width: 100%;
  }
  #login #log-section #content-login .tab-content input[type="text"],
  #login #log-section #content-login .tab-content input[type="password"] {
    width: 85%;
    margin-right: 0;
  }
  #login #log-section #content-login .tab-content .accion {
    display: block;
    min-width: 90%;
    padding: 0 5%;
  }
  /* CONSULTA ESTADO SOLICITUD */
  #consulta #log-section #content-login .tab-content .form-general label {
    margin-top: 0;
    margin-bottom: 0.3em;
    width: 100%;
  }
  #consulta #log-section #content-login .tab-content .form-general {
  float: left;
  width: 100%;
  }
  #consulta #log-section #content-login .tab-content input[type="text"],
  #consulta #log-section #content-login .tab-content input[type="password"] {
    width: 90%;
    margin-right: 0;
  }
  #consulta #log-section #content-login .tab-content .accion {
    display: block;
    min-width: 100%;
    padding: 0 5%;
  }

  #head-sala-prensa .filtros {
    width: 100%;
    margin: 0 0 1em 0;
  }
  #head-sala-prensa .accion {
    /*width: 90%;*/
    padding: 0 5%;
    float:right;
  }
  .box1 {
    width: 100%;
  }
  /*#productos2{
        display:none;
    }*/
  /*.hazte-cliente{
        display:none;
    }*/
  .bg-foo {
    display: none;
  }
  #login #consejos-seguridad {
    border-left: none;
    margin-left: 0;
    padding-left: 0;
  }
  #login #consejos-seguridad .accion {
    display: block;
  }
  #footer .left-foo {
    display: none;
  }
  #footer .right-foo {
    text-align: center;
  }
  #footer .right-foo .fono-info {
    display: inline-blocK;
    float: none;
    margin-bottom: 20px;
    display: block;
  }
  #footer .right-foo .sociales-foo {
    display: inline-blocK;
    float: none;
  }
  #footer .right-foo > ul li {
    float: none;
    display: block;
    border-bottom: 1px #ccc solid;
    border-right: none;
    text-align: left;
  }
  #footer .right-foo > ul li:hover {
    background: #eaeaea;
  }
  #footer .right-foo > ul li:first-child {
    border-top: 1px #ccc solid;
    padding-left: 9px;
  }
  #footer .right-foo > ul a {
    padding: 10px 0;
    text-align: center;
  }
  #footer .right-foo .legal p {
    font-size: 0.6em;
    text-align: left;
  }
  /* TABLAS RESPONSIVE */
  .table-responsive {
    border: 1px solid #DDDDDD;
    margin-bottom: 15px;
    overflow-x: scroll;
    overflow-y: hidden;
    width: 100%;
  }
  .bs-example > .table-responsive > .table {
    background-color: #FFFFFF;
  }
  .table-responsive > .table-bordered {
    border: 0 none;
  }
  .table-responsive > .table {
    margin-bottom: 0;
  }
  .table-responsive > .table > thead > tr > th,
  .table-responsive > .table > tbody > tr > th,
  .table-responsive > .table > tfoot > tr > th,
  .table-responsive > .table > thead > tr > td,
  .table-responsive > .table > tbody > tr > td,
  .table-responsive > .table > tfoot > tr > td {
    white-space:normal;
  }
  .table-responsive > .table-bordered > thead > tr > th:first-child,
  .table-responsive > .table-bordered > tbody > tr > th:first-child,
  .table-responsive > .table-bordered > tfoot > tr > th:first-child,
  .table-responsive > .table-bordered > thead > tr > td:first-child,
  .table-responsive > .table-bordered > tbody > tr > td:first-child,
  .table-responsive > .table-bordered > tfoot > tr > td:first-child {
    border-left: 0 none;
  }
  .table-responsive > .table-bordered > thead > tr > th:last-child,
  .table-responsive > .table-bordered > tbody > tr > th:last-child,
  .table-responsive > .table-bordered > tfoot > tr > th:last-child,
  .table-responsive > .table-bordered > thead > tr > td:last-child,
  .table-responsive > .table-bordered > tbody > tr > td:last-child,
  .table-responsive > .table-bordered > tfoot > tr > td:last-child {
    border-right: 0 none;
  }
  #comparador-tc{
  padding: 3%;
    width: 94%;
  }
#banner .flexslider.site .item .carousel-caption {
  padding: 1.5rem 3% 0;
    position: static;
    width: 96%;
  }
#banner .prod-dest .slides  .item > img, #banner .slides.prod-dest .item > a > img {
    display: block;
}
#banner .flexslider.landing .carousel-caption > img {
    display: none;
}
  #banner .esp .item .carousel-caption h2{
    color: #333;
    font-size: 2.8rem;
  line-height:normal;
  text-align:left;
  }
  #banner .flexslider.prod-dest .slides .item .carousel-caption {
  position:static;
  }
  #banner .flexslider.esp .item {
    padding: 1rem 0.5rem;
    height: auto;
  }
  #banner .site .item > img {
    display:none;
  }
  #banner .site .item .carousel-caption {
  padding: 1.5rem 3% 0;
    position: static;
    width: 96%;
  }
  #banner .flexslider.site .item{
    height:auto;
  }
     #banner .flexslider.site .slides .item .carousel-caption h2, #banner .site .slides .item .carousel-caption p{
    color:#333;
  }
  #banner .flexslider.prod-dest .slides .item .carousel-caption h2, #banner .site .slides .item .carousel-caption p{
    color:#333;
  }
  #banner .flexslider.prod-dest .item > img, #banner .flexslider.prod-dest .item > a > img{
    display:none;
  }
  #banner .flexslider.prod-dest .item{
    height:auto;
  }
  #banner .flexslider.prod-dest .item .head-modulo{
    padding: 5%;
    position: static;
    width: 90%;
  }
  #banner .flexslider.prod-dest .item .head-modulo h2, #banner .flexslider.prod-dest .item .head-modulo p{
    color:#333;
  }
  #banner-noticias .carousel-inner > .item > img {
    display:none;
  }
  #banner.hazte-cliente{
    display:none;
  }
  #banner .flexslider.landing .carousel-caption > img{  
  display:none;
  }
    
  .comparador-tc{
  padding: 2%;
    width: 96%;
  }
  .comparador-tc .accion {
    margin:0.5rem 0 0 0;
  float:left;
  }
  #simulador {
    margin-top: 0px;
    }
  .form-general label {
      width:100%;
      margin-bottom:2%;
  }
      .form-general .has-feedback {
      margin-right: 0;
      width: 100%;
      position:relative;
     /* height: 90px;*/
  }
      
  .form-general input[type="text"], .form-general input[type="password"],
  .form-general input[type="tel"] {
      display: block;
      margin-right: 0;
      width: 93%;
  }
  .form-general .variation {
      margin: 0 0 0 0;
      width: 100%;
  }
  .form-general .dia {
      width: 30%;
  }
  .form-general .mes{
      width: 30%;
      margin-left: 3%;
  }
  .form-general .ano{
      width: 30%;
      margin-left: 3%;
  }
  .ui-datepicker-trigger {
      right: 0;
      bottom: 0;
      top:inherit;
  }
  .has-feedback .form-control-feedback {
      top: 42px;
      right:0;
      }
  .form-group > .tooltip-help-pers {
     position:absolute!important;
     top:10px;
     right:5px;
  }
   .ayuda-pers {
      width:90%;
  }
  tr.desplegable_tr td .ayuda-pers {
      margin-top: 0;
      width: 90%;
  }
  #banner .slide.esp .item .carousel-caption > img, #banner .flexslider.slide.esp .item > img{  
      display:none;
  }
  .form-group textarea {
    width: 57%;
    max-width: 57%;
    padding: 4px 10px;
    height: 150px;
  }
  .captcha-input {
  margin: 1% 0% 0% 0% !important;
  margin-top: 3%;
  }
  .inner-content-logo .back-btn {
  display: block;
}
#menuMobile {
  display: none;
}
#pasosDesktop {
  display: block;
}
.verificacion {
  text-align: center;
  margin-left: 0%;
}
#codigo {
  text-align: center;
  margin: 0px 15%;
}
}
@media (min-width: 480px) and (max-width: 760px) {
  .llamado h2 {
    font-size: 1.8em;
  }
  .llamado h3 {
    font-size: 1.3em;
  }
  .form-general {
    display: table;
    margin-bottom: 10px;
    width: 100%;
    vertical-align: middle;
}
  .accion.ejecucion {
    min-width: 120px!important;
    width: 100%;
    margin-left: 0px;
    float: none;
 }
  .comparador-tc .accion {
    margin:0;
  float:right;
  }
  #banner .owl-carousel .item {
    height: 235px;
  }
  #banner .owl-carousel .item .carousel-caption {
    bottom: 35%;
    top: 31%;
    width: 62%;
}
    
  #login #log-section h2 { font-size: 2em; }

  .article-ico {
    padding: 0 10px!important;
  }

  .content-variable-landing {
    margin-bottom: 40px;
  }

  #wrap-productos .grid {
    margin-bottom: 0;
  }

  .content-variable-landing .var-content .simulador ul {
    margin-bottom: 0;
  }

  #footer .right-foo {
    padding-top: 0;
  }

  .tipo-productos .content-tbl .productHeadings th h6 {
    min-height: 80px;
  }

  #banner .carousel.hcliente .item .carousel-caption.center {
    width: 90%;
  }

   #banner .carousel.hcliente .item .carousel-caption.center p {
    font-size: 1em;
    line-height: 1.2em;
   }
   .btn-acciones {
    display: block;
    width: 100%;
    margin: auto;
  }
   .btn-acciones .accion {
    min-width: 110px!important;
    width: 40%;
  }
  .accion.ejecucion {
    min-width: 120px!important;
    width: 100%;
    margin-left: 0px;
    float: none;
  }
  .btn-acciones a.accion {
  margin-left: 0.5em;
  }
  .btn-acciones .btn-derecha {
    float: right;
    margin-right: 0px;
  }
  .form-group textarea {
    width: 93%;
    max-width: 93%;
    padding: 4px 10px;
    height: 150px;
  }
  .captcha-input {
  margin: 2% 0% 0% 0% !important;
  margin-top: 3%;
  }
  div.variation span.select {
  background: url("../../images/Pescadores/bg_select.png") no-repeat scroll right top #ffffff;
  border: 1px #ccc solid;
  bottom: 0;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  cursor: default;
  font-size: 12px;
  height: 40px;
  left: 0;
  line-height: 40px;
  padding-right: 20%;
  position: absolute;
  text-indent: 10px;
  overflow: hidden;
  width: 77%;
  z-index: 2;
}
/* CONSULTA ESTADO SOLICITUD */
  #consulta #log-section #content-login .tab-content .form-general label {
    margin-top: 0;
    margin-bottom: 0.3em;
    width: 100%;
  }
  #consulta #log-section #content-login .tab-content .form-general {
  float: left;
  width: 100%;
  }
  #consulta #log-section #content-login .tab-content input[type="text"],
  #consulta #log-section #content-login .tab-content input[type="password"] {
    width: 95%;
    margin-right: 0;
  }
  #consulta #log-section #content-login .tab-content .accion {
    display: block;
    min-width: 100%;
    padding: 0 5%;
  }
  .inner-content-logo .back-btn {
  display: block;
}
#menuMobile {
  display: block;
}
#pasosDesktop {
  display: none;
}
#select-medio-pago input[type="radio"] {
    width: 30px;
    height: 20px;
    line-height: 50px;
}
#condiciones {
    text-align: left;
}
#condiciones label{
  width: 80%;
  display: inline-table;
  text-align: left;
}
#condiciones input[type="checkbox"] {
    width: 30px;
    height: 20px;
    line-height: 50px;
}
.verificacion {
  text-align: center;
  margin-left: 0%;
}
#codigo {
  text-align: left;
  margin: 30px 0%;
  width: 95%;
}
#codigo .form-general {
  display: table;
  margin-bottom: 20px;
  width: 100%;
  vertical-align: middle;
}
#codigo .form-general .has-feedback {
  width: 100%;
}
#codigo .form-general input[type="text"], 
#codigo .form-general input[type="password"], 
#codigo .form-general input[type="tel"] {
  display: inline;
  width: 100%;
}
}

@media (max-width: 480px) { 
  .llamado h2 {
    font-size: 1.8em;
  }
  .llamado h3 {
    font-size: 1.3em;
  }
  #site-side .article-img .ver-mas {
    display: none;
  }
  .accion.ejecucion {
    min-width: 120px!important;
    width: 100%;
    margin-left: 0px;
    float: none;
  }
  .form-group textarea {
    width: 93%;
    max-width: 93%;
    padding: 4px 10px;
    height: 150px;
  }
  .form-general {
    display: table;
    margin-bottom: 10px;
    width: 100%;
    vertical-align: middle;
}
[class*="col-"] + [class*="col-"] {
    margin: 0em 0 0 0;
  }
  html #footer .right-foo > ul li a {
    font-size: 1em;
  }

 .btn-acciones .accion {
    min-width: 40%!important;
  }
  /*.accion.ejecucion {
    min-width: 45%!important;
    margin-left: 0.8em;
  }*/

  .btn-acciones {
    display: block;
    width: 100%;
    margin: auto;
  }
  .btn-acciones .btn-derecha {
    float: right;
    margin-right: 0px;
  }
  #faq #head-faq .accion {
    min-width: 82px;
  }

  .article-img .ver-mas { display: none; }

/*  #banner .carousel .item { height: 155px;}*/

#myCarousel.hcliente {
  display: none;
}

  #footer .right-foo > ul li a {
    display: block;
}
.bajada p {
    font-size: 1em;
    line-height: 120%;
}   
#banner .slides .item {

}
  .error-txt {
  color: #b91a28;
  float: left;
  margin-left: none;
  margin-top: 0.5em;
  }
  .restante_caracteres {
  float: left;
  margin-left: none ;
  margin-top: 0.5em;
  }
  input.error-txt {
  margin-left:0%;
  }
  .captcha-input {
  margin: 1% 0% 0% 0%!important;
  margin-top: 3%;
  }
  /*.llamado  h2 {
    font-family: open_sanslight;
    font-size: 1.692em;
    line-height: 27px;
  }
   .llamado h2 span {
    display: block;
    text-align: center;
    float: none;
    margin-top: 20px;
    font-weight: 900;
    font-size: 1.9em;
  }*/

  /* Consulta de Estado de Solicitud*/
  #consulta #log-section #content-login .tab-content .form-consulta{
  float: none;
  width: 100%;
}
.captcha div {
  margin-top: 35px;
  padding-top: 6px;
}
.select-plan-home, .select-plan-home select {
  width: 100%!important;
  margin-bottom: 35px!important;
}
#footer {
    height: auto;
}
.inner-content-logo .back-btn {
  display: block;
}
#menuMobile {
  display: block;
}
/* Pasos Desktop */

#pasosDesktop {
  display: none;
}

#select-medio-pago input[type="radio"] {
    width: 30px;
    height: 20px;
    line-height: 50px;
}
#condiciones {
    text-align: left;
}
#condiciones label{
  width: 80%;
  display: inline-table;
  text-align: left;
}
#codigo #condiciones label {
    width: 100%;
    display: inline-table;
    text-align: left;
}
#condiciones input[type="checkbox"] {
    width: 30px;
    height: 20px;
    line-height: 50px;
}
#codigo {
  text-align: left;
  margin: 30px 0%;
  width: 95%;
}
#codigo .form-general {
  display: table;
  margin-bottom: 20px;
  width: 100%;
  vertical-align: middle;
}
#codigo .form-general .has-feedback {
  width: 100%;
}
#codigo .form-general input[type="text"], 
#codigo .form-general input[type="password"], 
#codigo .form-general input[type="tel"] {
  display: inline;
  width: 100%;
}
.verificacion {
  text-align: center;
  margin-left: 0%;
} 

}

@media (max-width: 760px) {

    label.error {
        margin-left: 0%;
        color: #ff0000;
    }
}
